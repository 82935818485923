import { UiOptionData } from '@vkph/ui';

import { ServiceRolesGroupDataId } from '@vkph/common/types';
import {
  PermissionRoleDetailModel,
  PermissionRoleId,
  PermissionRoles,
  UserIdModel,
} from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';

import { PermissionSettingsType } from '../../components/permission-settings-fields';
import { getGroupRoleToOption } from '../role/getGroupRoleToOption';
import { getUserRoleToOption } from '../role/getUserRoleToOption';

export const getPermissionType = (isOverride: boolean, list: unknown[]): PermissionSettingsType => {
  if (isOverride && !list.length) {
    return PermissionSettingsType.Nobody;
  }

  if (!isOverride && !list.length) {
    return PermissionSettingsType.Anyone;
  }

  return PermissionSettingsType.Some;
};

type RolesFormData = {
  permissionType: PermissionSettingsType;
  groups?: UiOptionData[];
  users?: UiOptionData[];
};

export const getPermissionRoleDataForm = (role?: PermissionRoleDetailModel): Required<RolesFormData> => {
  const groups = (role?.groups || []).map(({ id, name, displayName }) =>
    getGroupRoleToOption({ id, name: displayName || name }),
  );

  const users = (role?.users || []).map((user) => {
    const { keycloakId, avatar, firstName, lastName, username } = user;

    return getUserRoleToOption({
      id: keycloakId || '',
      avatar: avatar || '',
      name: getFullNameWithoutPatronymic({ firstName, lastName, username }),
    });
  });
  const permissionType = getPermissionType(Boolean(role?.isOverride), [...groups, ...users]);

  return {
    permissionType,
    users,
    groups,
  };
};

type PermissionRoleAssign = {
  id: PermissionRoleId;
  users: UserIdModel[];
  groups: ServiceRolesGroupDataId[];
  isOverride: boolean;
};
type FindPermissionRoleParams = {
  role: PermissionRoles;
  roles: PermissionRoleDetailModel[];
};

export const findPermissionRole = (
  params: FindPermissionRoleParams,
): PermissionRoleDetailModel | undefined => {
  const { roles, role } = params;

  return roles.find((permissionRole) => permissionRole.name === role);
};

interface GetPermissionRoleAssignParams extends RolesFormData {
  id?: PermissionRoleId;
}

export const getPermissionRoleAssign = (params: GetPermissionRoleAssignParams): PermissionRoleAssign => {
  const { id, groups, permissionType, users } = params;

  const isOverride = permissionType !== PermissionSettingsType.Anyone;
  const isSendList = permissionType === PermissionSettingsType.Some;

  return {
    id: id || 0,
    groups: isSendList && groups ? groups.map(({ value }) => value) : [],
    users: isSendList && users ? users.map(({ value }) => value) : [],
    isOverride,
  };
};
