import React, { FC, RefObject } from 'react';

import { defaultValueCell } from '@vkph/common/store/lists';
import {
  DictionaryResponseCellValue,
  ListCellValues,
  ListColumnFieldType,
  ListColumnModel,
  ListFileResponse,
  ListRowId,
  ListRowValues,
  UserModel,
} from '@vkph/common/types/models';
import ClearSvg from '@vkph/ui/svg/clear-filled.svg';

import { ActionsDropdown } from '../../actions-dropdown';
import { BooleanCell } from './boolean/BooleanCell';
import { DateCell } from './date/DateCell';
import { DictionaryCell } from './dictionary/DictionaryCell';
import { EnumCell } from './enum/EnumCell';
import { FileCell } from './file/FileCell';
import { LinkCell } from './link/LinkCell';
import { NumericCell } from './number/NumericCell';
import { TextCell } from './text/TextField';
import { DynamicTableCellContentProps } from './types';
import { UserCell } from './user/UserCell';

export interface ListRowValuesWithId extends ListRowValues {
  id: ListRowId;
}

export type DynamicTableCellProps = {
  containerRef: RefObject<HTMLDivElement>;
  record: ListRowValuesWithId;
  onUpdate: (rowId: ListRowId, rowValues: ListRowValues) => Promise<void>;
  column: ListColumnModel;
  readOnly?: boolean;
};

const isUserType = (value: ListCellValues): value is UserModel => typeof value === 'object' && 'id' in value;

const isFileType = (value: ListCellValues): value is ListFileResponse =>
  typeof value === 'object' && 'id' in value && 'file' in value;

const isBooleanTypeArray = (value: ListCellValues): value is [boolean, string] =>
  Array.isArray(value) && typeof value[0] === 'boolean';

const isDictionaryResponse = (value: ListCellValues): value is DictionaryResponseCellValue[] =>
  Array.isArray(value) &&
  value.some(
    (item: unknown) =>
      item && typeof item === 'object' && 'dictionaryValueId' in item && 'dictionaryType' in item,
  );

const normalizeValueCell = (value: ListCellValues) => {
  if (isFileType(value)) {
    return value.id;
  }

  if (isUserType(value)) {
    return value.keycloakId || value.id;
  }

  if (isBooleanTypeArray(value)) {
    return value[0];
  }

  if (isDictionaryResponse(value)) {
    return {
      dictionaryType: value[0].dictionaryType,
      dictionaryValuesIds: value.map((node) => node.dictionaryValueId),
    };
  }

  return value;
};

export const normalizeValuesRow = (rowValues: ListRowValues) => {
  return Object.fromEntries(
    Object.entries(rowValues).map(([key, cellValue]) => {
      return [key, normalizeValueCell(cellValue)];
    }),
  );
};

const isCellOfType = <Type extends ListCellValues>(
  props: DynamicTableCellContentProps<ListCellValues>,
  expectedType: ListColumnFieldType,
): props is DynamicTableCellContentProps<Type> => props.fieldType === expectedType;

export const DynamicTableCell: FC<DynamicTableCellProps> = (props) => {
  const { record, containerRef, onUpdate, column, readOnly } = props;
  const { id: dataIndex, fieldType, fieldOptions, isRequired } = column;
  const { id: rowId, ...rowValues } = record;

  const value = record[dataIndex];

  const onClearCell = () => {
    const data = {
      ...rowValues,
      [dataIndex]: fieldOptions?.default || defaultValueCell[fieldType],
    };

    const { children, key, ...restData } = data;

    onUpdate(rowId, normalizeValuesRow(restData));
  };

  const onFinish = (values: ListRowValues) => {
    const valueForm = values[dataIndex];

    if (valueForm === value) {
      return null;
    }

    const data = {
      ...rowValues,
      [dataIndex]: valueForm,
    };

    const { children, key, ...restData } = data;

    return onUpdate(rowId, normalizeValuesRow(restData));
  };

  const items = [
    {
      key: 'clear',
      label: 'Очистить ячейку',
      icon: ClearSvg,
      onClick: () => onClearCell(),
    },
  ];

  const cellProps: DynamicTableCellContentProps = {
    readOnly,
    containerRef,
    onFinish,
    value,
    dataIndex,
    fieldType,
    fieldOptions,
    isRequired,
    inputSuffix: readOnly ? undefined : (
      <ActionsDropdown
        trigger={['click']}
        getPopupContainer={() => containerRef?.current || document.body}
        items={items}
      />
    ),
  };

  return (
    <>
      {isCellOfType<number>(cellProps, ListColumnFieldType.Numeric) && <NumericCell {...cellProps} />}
      {isCellOfType<string>(cellProps, ListColumnFieldType.Text) && <TextCell {...cellProps} />}
      {isCellOfType<[boolean, string]>(cellProps, ListColumnFieldType.Boolean) && (
        <BooleanCell {...cellProps} />
      )}
      {isCellOfType<string>(cellProps, ListColumnFieldType.Hyperlink) && <LinkCell {...cellProps} />}
      {isCellOfType<DictionaryResponseCellValue[]>(cellProps, ListColumnFieldType.Dictionary) && (
        <DictionaryCell {...cellProps} />
      )}
      {isCellOfType<string[]>(cellProps, ListColumnFieldType.Enum) && <EnumCell {...cellProps} />}
      {isCellOfType<UserModel>(cellProps, ListColumnFieldType.User) && <UserCell {...cellProps} />}
      {isCellOfType<string>(cellProps, ListColumnFieldType.Datetime) && <DateCell {...cellProps} />}
      {isCellOfType<ListFileResponse>(cellProps, ListColumnFieldType.File) && <FileCell {...cellProps} />}
    </>
  );
};
