import { UiCheckbox, UiCheckboxProps, UiRadio, UiRadioProps } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import { PollButtonTypes, POLL_BUTTON_TYPE_DEFAULT } from '../constants';
import styles from './PollButton.scss';

export type PollButtonItemProps = UiRadioProps | UiCheckboxProps;
export type PollButtonTypesProps = {
  type?: PollButtonTypes;
};
export type PollButtonProps<T = PollButtonItemProps> = T & PollButtonTypesProps;

export const PollButton: FC<PollButtonProps> = (props) => {
  const { type = POLL_BUTTON_TYPE_DEFAULT, className, ...restProps } = props;
  const classNameStyles = classNames(styles.pollButton, className);
  const PollButtonComponent = type === PollButtonTypes.Single ? UiRadio : UiCheckbox;

  return (
    <PollButtonComponent {...restProps} className={classNameStyles}>
      {restProps.children}
    </PollButtonComponent>
  );
};
