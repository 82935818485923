import { uid } from '@ckeditor/ckeditor5-utils';

import type { MentionAttribute } from './Mention';

/**
 * @internal
 */
// eslint-disable-next-line no-underscore-dangle
export function addMentionAttributes(
  baseMentionData: MentionAttribute,
  data?: Partial<MentionAttribute>,
): MentionAttribute {
  return { uid: uid(), ...baseMentionData, ...(data || {}) };
}
