import { UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './MonthHeading.scss';

export const daysOfWeekRu = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

export const MonthHeading: FC = () => {
  const isDayOff = (index: number) => index > 4;

  return (
    <div className={styles.monthHeading}>
      {daysOfWeekRu.map((title, index) => (
        <div key={title} className={styles.monthHeading__cell}>
          <UiTypography.Text
            className={classNames(styles.monthHeading__title, {
              [styles.monthHeading__title_dayOff]: isDayOff(index),
            })}
          >
            {title}
          </UiTypography.Text>
        </div>
      ))}
    </div>
  );
};
