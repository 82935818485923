import { UiCell } from '@vkph/ui';
import React from 'react';

import { ServiceRolesGroupDataModel } from '@vkph/common/types';
import AvatarUsersSvg from '@vkph/ui/svg/avatar-users.svg';
import { OptionModel } from '@vkph/ui/types/option';

import { MultiSelectAvatarLabelDataType } from '../../components/multi-select-avatar-label';
import { UserAvatar } from '../../components/user-avatar';

type GroupRoleToOptionParams = Pick<ServiceRolesGroupDataModel, 'id' | 'name'>;

export const getGroupRoleToOption = (
  params: GroupRoleToOptionParams,
): OptionModel<GroupRoleToOptionParams['id'], MultiSelectAvatarLabelDataType> => {
  const { id, name } = params;

  return {
    value: id,
    data: {
      label: <UiCell title={name} avatar={<UserAvatar size="extraSmall" icon={<AvatarUsersSvg />} />} />,
      selectedLabel: name,
    },
  };
};
