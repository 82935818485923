import { UiRow, UiRowProps, AvatarSize, UiAvatarProps } from '@vkph/ui';
import React, { FC, PropsWithChildren, ReactNode, SyntheticEvent, useMemo } from 'react';

import { useCurrentProfile } from '@vkph/common/providers';
import { BlogFullModel, UserModel } from '@vkph/common/types/models';
import {
  getFullNameWithoutPatronymic,
  getRoutePath,
  RouteNames,
  getFormattedRelativeDate,
} from '@vkph/common/utils';

import { UiUser, UiUserInfoProps } from '../../user';
import { PostHeaderActions } from './actions/PostHeaderActions';
import { PostHeaderBlogLink } from './blog-link/PostHeaderBlogLink';
import { PostHeaderSkeleton } from './skeleton/PostHeaderSkeleton';

export interface PostHeaderProps
  extends Omit<UiRowProps, 'align'>,
    Pick<UiUserInfoProps, 'extra' | 'spaceProps'> {
  blogs?: BlogFullModel[];
  subTitle?: ReactNode;
  author: UserModel;
  postDate: string;
}

interface AvatarPropsType extends Pick<UiAvatarProps, 'src'> {
  size: AvatarSize;
}

type PostHeaderComponent = {
  Actions: typeof PostHeaderActions;
  Skeleton: typeof PostHeaderSkeleton;
};

export const PostHeader: PostHeaderComponent & FC<PropsWithChildren<PostHeaderProps>> = (props) => {
  const { author, postDate, blogs = [], children, subTitle, extra, spaceProps, ...restProps } = props;

  const profile = useCurrentProfile();
  const authorId = author.keycloakId || author.id;
  const isAuthorActive = Boolean(author.isActive || author.keycloakUser?.isActive);

  const avatarProps = {
    size: 'middle',
    src: profile?.id === authorId ? profile?.smallAvatar : author.smallAvatar,
  } satisfies AvatarPropsType;

  const UserInfoSubtitle = useMemo(() => {
    if (subTitle) {
      return subTitle;
    }

    return (
      <>
        {getFormattedRelativeDate(postDate, 'dateTime')}
        {blogs.length > 0 && <PostHeaderBlogLink blogs={blogs} />}
      </>
    );
  }, [postDate, subTitle]);

  const onRedirection = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <UiRow justify="space-between" wrap={false} {...restProps}>
      <UiUser.Info
        {...spaceProps}
        avatarProps={avatarProps}
        isActive={isAuthorActive}
        title={getFullNameWithoutPatronymic(author)}
        subtitle={UserInfoSubtitle}
        to={getRoutePath(RouteNames.Profile, { id: authorId })}
        onClick={onRedirection}
        extra={extra}
        shift={4}
      />
      {children}
    </UiRow>
  );
};

PostHeader.Actions = PostHeaderActions;
PostHeader.Skeleton = PostHeaderSkeleton;
