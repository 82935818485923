import { UiForm, UiInputRef, UiTooltip, useSpace } from '@vkph/ui';
import React, { FC, useEffect, useRef } from 'react';

import { ListRowValues } from '@vkph/common/types/models';
import { regexpPatterns, requiredRule, validateMaxValue, validateMinValue } from '@vkph/common/utils';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<number>;

const MAX_LENGTH = 21;

export const NumericCell: FC<Props> = (props) => {
  const { fieldOptions, dataIndex, value, onFinish, isRequired, inputSuffix, readOnly } = props;
  const { maxValue: max, minValue: min } = fieldOptions?.range || {};
  const inputRef = useRef<UiInputRef>(null);

  const [form] = UiForm.useForm();
  const { spaceXL } = useSpace();

  const rules = [
    {
      message: 'Пожалуйста, введите числовое значение',
      pattern: regexpPatterns.number,
    },
    { ...(min && validateMinValue(min, 'значение за пределами диапазона')) },
    { ...(max && validateMaxValue(max, 'значение за пределами диапазона')) },
    { ...(isRequired && requiredRule) },
  ];

  const onSubmit = () => {
    form.submit();
  };

  const onSave = () => {
    onSubmit();
  };

  const clearFocus = () => {
    inputRef.current?.blur();
  };

  const oFinishForm = (values: ListRowValues) => {
    const inputValue = values[dataIndex] ? Number(values[dataIndex]) : values[dataIndex];

    onFinish({ ...values, [dataIndex]: inputValue });
  };

  const initialValues = {
    [dataIndex]: value || fieldOptions.default,
  };

  useEffect(() => {
    form.setFieldValue([dataIndex], value ? value.toString() : fieldOptions.default);
  }, [value, fieldOptions.default]);

  return (
    <UiForm initialValues={initialValues} onFinish={oFinishForm} form={form}>
      <UiTooltip
        placement="top"
        shift={spaceXL}
        title={value && value.toString().length > MAX_LENGTH ? value : null}
      >
        <UiForm.Item style={{ marginBottom: 0 }} name={dataIndex} rules={rules}>
          <CellInput
            ref={inputRef}
            onPressEnter={readOnly ? undefined : clearFocus}
            onBlur={readOnly ? undefined : onSave}
            placeholder={readOnly ? undefined : 'Добавить число'}
            suffix={inputSuffix}
            readOnly={readOnly}
          />
        </UiForm.Item>
      </UiTooltip>
    </UiForm>
  );
};
