import {
  UiCheckbox,
  UiCheckboxOptionType,
  UiCheckboxValueType,
  UiRadio,
  UiRadioGroupOnChange,
  UiRadioGroupOptions,
  UiSpace,
} from '@vkph/ui';
import mapValues from 'lodash/mapValues';
import React, { FC, useCallback, useMemo } from 'react';

import { PostSettings, PostSettingsVisibilityType, PostTypes } from '@vkph/common/types/models';
import { getIdentityObjectKeys } from '@vkph/common/utils';
import GearSvg from '@vkph/ui/svg/gear.svg';
import ViewSvg from '@vkph/ui/svg/view.svg';

type InteractionsSettings = Pick<PostSettings, 'isComments' | 'isReacted'>;

const INTERACTIONS_SETTINGS_LABELS: Partial<Record<keyof PostSettings, string>> = {
  isReacted: 'Разрешить реакции',
  isComments: 'Разрешить комментировать',
};

const availabilitySettingsItems: UiRadioGroupOptions = [
  { value: PostSettingsVisibilityType.Followers, label: 'Подписчикам' },
  { value: PostSettingsVisibilityType.All, label: 'Всем пользователям' },
];

interface Props {
  postType: PostTypes;
  settings?: PostSettings;
  isShowSettingsVisibility?: boolean;
  onSettingsChange?: (settings: PostSettings) => void;
}

export const PostFooterSettings: FC<Props> = (props) => {
  const { settings, onSettingsChange, postType, isShowSettingsVisibility } = props;

  const { interactionsSettings, availabilitySettingsValues } = useMemo<{
    interactionsSettings: InteractionsSettings;
    availabilitySettingsValues: PostSettingsVisibilityType;
  }>(
    () => ({
      interactionsSettings: {
        isComments: settings?.isComments ?? true,
        isReacted: settings?.isReacted ?? true,
      },
      availabilitySettingsValues: settings?.visibility || PostSettingsVisibilityType.Followers,
    }),
    [settings],
  );

  const interactionsSettingsValues = useMemo<string[]>(
    () => (interactionsSettings ? getIdentityObjectKeys(interactionsSettings) : []),
    [interactionsSettings],
  );

  const interactionsSettingsItems = useMemo<UiCheckboxOptionType[]>(() => {
    if (interactionsSettings) {
      return Object.keys(interactionsSettings).map((key: string) => ({
        value: key,
        label: INTERACTIONS_SETTINGS_LABELS[key as keyof PostSettings],
      }));
    }

    return [];
  }, [settings]);

  const onChangeInteractionsSettings = useCallback(
    (active: UiCheckboxValueType[]) => {
      if (settings && interactionsSettings) {
        const activeSet = new Set(active);
        const newSettings = mapValues(interactionsSettings, (_, key) => activeSet.has(key));

        onSettingsChange?.({ ...settings, ...newSettings });
      }
    },
    [settings, interactionsSettings, onSettingsChange],
  );

  const onChangeAvailabilitySettings = useCallback<UiRadioGroupOnChange>(
    (e) => {
      if (settings) {
        onSettingsChange?.({
          ...settings,
          visibility: e?.target?.value || PostSettingsVisibilityType.Followers,
        });
      }
    },
    [settings, onSettingsChange],
  );

  return (
    <UiSpace size="middle">
      {postType === PostTypes.Micropost && isShowSettingsVisibility && (
        <UiRadio.Menu
          placement="topRight"
          icon={ViewSvg}
          onChange={onChangeAvailabilitySettings}
          value={availabilitySettingsValues}
          items={availabilitySettingsItems}
        />
      )}
      <UiCheckbox.Menu
        placement="topRight"
        icon={GearSvg}
        onChange={onChangeInteractionsSettings}
        value={interactionsSettingsValues}
        items={interactionsSettingsItems}
      />
    </UiSpace>
  );
};
