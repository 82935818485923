import {
  UiButton,
  UiCheckbox,
  UiDivider,
  UiDrawer,
  UiDrawerProps,
  UiFile,
  UiFileDirection,
  UiFlex,
  UiIcon,
  UiTruncateMarkup,
  UiTypography,
  getFormattedFileSize,
  useSpace,
} from '@vkph/ui';
import React, { FC } from 'react';

import { useProfileStorage } from '@vkph/common/store/profile/hooks';
import {
  DictionaryResponseCellValue,
  ListCellValues,
  ListColumnFieldType,
  ListColumnModel,
  ListFileResponse,
  ListRowModel,
  UserModel,
} from '@vkph/common/types/models';
import {
  RouteNames,
  getFormattedDate,
  getFullName,
  getFullNameWithoutPatronymic,
  getRoutePath,
} from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';

import { UiUser } from '../../user';
import { listColumnTypeFields } from '../constants';

interface CellType<T extends ListCellValues = ListCellValues>
  extends Pick<ListColumnModel, 'id' | 'fieldType' | 'name' | 'fieldOptions'> {
  value: T;
}

const isCellOfTypes = <Type extends ListCellValues>(
  cell: CellType<ListCellValues>,
  expectedTypes: ListColumnFieldType[],
): cell is CellType<Type> => expectedTypes.includes(cell.fieldType);

interface RowDetailsProps extends Omit<UiDrawerProps, 'onClose'> {
  rowData: ListRowModel;
  columnsData: ListColumnModel[];
  onClose: () => void;
}

const renderEnumCellValues = (values: string[]) =>
  values.map((value) => <UiTypography.Text key={value}>{value}</UiTypography.Text>);

export const RowDetails: FC<RowDetailsProps> = (props) => {
  const { rowData, columnsData, onClose, ...restProps } = props;
  const { createdById, updatedById, rowValues } = rowData;
  const { spaceXS, spaceS, spaceM, spaceXL } = useSpace();

  const { data: createdAuthor } = useProfileStorage({
    id: createdById,
  });

  const { data: updatedAuthor } = useProfileStorage({
    id: updatedById,
  });

  const cells = columnsData.map((column) => ({
    id: column.id,
    fieldType: column.fieldType,
    name: column.name,
    value: rowValues[column.id],
    fieldOptions: column.fieldOptions,
  }));

  const drawerStyles: UiDrawerProps['styles'] = {
    header: {
      padding: spaceXL,
      border: 'none',
    },
    footer: {
      padding: spaceXL,
    },
    body: {
      paddingTop: 0,
    },
  };

  return (
    <UiDrawer
      {...restProps}
      onClose={onClose}
      title={
        <UiTypography.Title level={3} style={{ marginBottom: 0 }}>
          Информация о строке
        </UiTypography.Title>
      }
      closeIcon={null}
      placement="right"
      styles={drawerStyles}
      footer={
        <UiButton type="secondary" size="large" block onClick={onClose}>
          Скрыть информацию
        </UiButton>
      }
    >
      <UiFlex vertical gap={spaceM}>
        {createdAuthor && (
          <UiFlex vertical gap={spaceXS}>
            <UiTypography.Text type="secondary" style={{ paddingTop: spaceXS }}>
              Автор
            </UiTypography.Text>
            <UiUser.Info
              subtitle={`Дата создания ${getFormattedDate(rowData.createdAt, 'dateTime')}`}
              to={getRoutePath(RouteNames.Profile, { id: createdAuthor.id })}
              title={getFullNameWithoutPatronymic(createdAuthor.fullName)}
              isActive={createdAuthor.isActive}
              avatarProps={{
                src: createdAuthor.smallAvatar,
              }}
            />
          </UiFlex>
        )}
        {updatedAuthor && (
          <UiFlex vertical gap={spaceXS}>
            <UiTypography.Text type="secondary" style={{ paddingTop: spaceXS }}>
              Автор изменений
            </UiTypography.Text>
            <UiUser.Info
              subtitle={`Дата изменений ${getFormattedDate(rowData.updatedAt, 'dateTime')}`}
              to={getRoutePath(RouteNames.Profile, { id: updatedAuthor.id })}
              title={getFullNameWithoutPatronymic(updatedAuthor.fullName)}
              isActive={updatedAuthor.isActive}
              avatarProps={{
                src: updatedAuthor.smallAvatar,
              }}
            />
          </UiFlex>
        )}
        {cells.map((cell) => {
          return (
            <UiFlex key={cell.id} vertical gap={spaceXS}>
              <UiFlex gap={spaceS} style={{ paddingTop: spaceS, paddingBottom: spaceS }}>
                <UiIcon
                  component={listColumnTypeFields[cell.fieldType].icon}
                  color={variables.themePalette.colorIcon}
                />
                <UiTypography.Text strong>{cell.name}</UiTypography.Text>
              </UiFlex>
              {isCellOfTypes<string | number>(cell, [
                ListColumnFieldType.Text,
                ListColumnFieldType.Numeric,
              ]) && <UiTypography.Text>{cell.value}</UiTypography.Text>}
              {isCellOfTypes<string>(cell, [ListColumnFieldType.Hyperlink]) && (
                <UiTypography.Link href={cell.value} target="_blank" ellipsis>
                  <UiTruncateMarkup truncate>{cell.value}</UiTruncateMarkup>
                </UiTypography.Link>
              )}
              {isCellOfTypes<[boolean, string] | undefined>(cell, [ListColumnFieldType.Boolean]) && (
                <UiCheckbox checked={cell.value?.[0] || false}>
                  {cell.value ? cell.fieldOptions.trueValueName : cell.fieldOptions.falseValueName}
                </UiCheckbox>
              )}
              {isCellOfTypes<UserModel | undefined>(cell, [ListColumnFieldType.User]) && cell.value && (
                <UiUser.Info
                  to={getRoutePath(RouteNames.Profile, { id: cell.value.id })}
                  title={getFullName(cell.value)}
                  isActive={cell.value.isActive}
                  avatarProps={{
                    size: 'extraSmall',
                    src: cell.value.avatar,
                  }}
                />
              )}
              {isCellOfTypes<string[]>(cell, [ListColumnFieldType.Enum]) &&
                cell.value &&
                renderEnumCellValues(cell.value)}
              {isCellOfTypes<ListFileResponse>(cell, [ListColumnFieldType.File]) && cell.value && (
                <UiFile
                  direction={UiFileDirection.Horizontal}
                  title={
                    <UiTypography.Paragraph style={{ textAlign: 'left', marginBottom: 0 }} strong>
                      <UiTruncateMarkup truncate>{cell.value.name}</UiTruncateMarkup>
                    </UiTypography.Paragraph>
                  }
                  subtitle={
                    <UiTypography.Text type="secondary">
                      <UiTruncateMarkup truncate>
                        {getFormattedFileSize(cell.value.fileData?.size)} <UiDivider.Dot type="secondary" />
                        {getFormattedDate(cell.value.fileData?.createdAt)}
                      </UiTruncateMarkup>
                    </UiTypography.Text>
                  }
                  fileName={cell.value.name}
                />
              )}
              {isCellOfTypes<string>(cell, [ListColumnFieldType.Datetime]) && cell.value && (
                <UiTypography.Text>
                  {getFormattedDate(cell.value, cell.fieldOptions?.onlyDate ? 'date' : 'dateTime')}
                </UiTypography.Text>
              )}
              {isCellOfTypes<DictionaryResponseCellValue>(cell, [ListColumnFieldType.Dictionary]) &&
                cell.value && <UiTypography.Text>{cell.value.dictionaryValue}</UiTypography.Text>}
            </UiFlex>
          );
        })}
      </UiFlex>
    </UiDrawer>
  );
};
