import { UiSelect, UiForm, UiFormItemLabelProps, getPopupContainerParentElement } from '@vkph/ui';
import React, { FC, ReactNode, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useUsersGroupsOptionsListStorage } from '@vkph/common/store/groups/hooks';
import { useProfileOptionsListStorage } from '@vkph/common/store/profile/hooks';

import { getGroupRoleToOption, getUserRoleToOption } from '../../utils/role';
import { MultiSelectField, MultiSelectFieldProps } from '../multiselect-field';
import { PermissionSettingsType } from './constants';

type FieldNamesTypes = 'users' | 'groups';

interface FieldNames extends Partial<Record<FieldNamesTypes, string>> {
  permission: string;
}

export type PermissionSettingsFieldsProps = {
  formNames: FieldNames;
  tooltipTitle?: ReactNode;
  permissionTypeLabels?: Record<PermissionSettingsType, string>;
  label: UiFormItemLabelProps['label'];
};

export const PermissionSettingsFields: FC<PermissionSettingsFieldsProps> = (props) => {
  const { label: permissionLabel, formNames, tooltipTitle, permissionTypeLabels } = props;
  const { groups, users, permission } = formNames;

  const permissionTypeOptions = useMemo(() => {
    return [
      {
        label: permissionTypeLabels?.[PermissionSettingsType.Anyone] || 'Все пользователи',
        value: PermissionSettingsType.Anyone,
      },
      {
        label: permissionTypeLabels?.[PermissionSettingsType.Some] || 'Некоторые пользователи',
        value: PermissionSettingsType.Some,
      },
      {
        label: permissionTypeLabels?.[PermissionSettingsType.Nobody] || 'Никто из пользователей',
        value: PermissionSettingsType.Nobody,
      },
    ];
  }, [permissionTypeLabels]);

  const {
    options: profileListOptions,
    onUpdate: onUpdateProfilesList,
    setQuerySearch: setProfileListQuerySearch,
  } = useProfileOptionsListStorage({
    autoFetchParams: { isActive: true },
  });

  const onSearchProfileListDebounced = useDebouncedCallback(onUpdateProfilesList, 500);

  const onSearchProfileList = (newSearchQuery: string) => {
    setProfileListQuerySearch(newSearchQuery);
    onSearchProfileListDebounced(newSearchQuery);
  };

  const {
    options: usersGroupsOptions,
    onUpdate: onUpdateUsersGroups,
    setQuerySearch: setUsersGroupsQuerySearch,
  } = useUsersGroupsOptionsListStorage();

  const onSearchUsersGroupsDebounced = useDebouncedCallback(onUpdateUsersGroups, 500);

  const onSearchUsersGroups = (newSearchQuery: string) => {
    setUsersGroupsQuerySearch(newSearchQuery);
    onSearchUsersGroupsDebounced(newSearchQuery);
  };

  const userOptions = useMemo(() => {
    return profileListOptions.map(({ value, label, avatar }) => {
      return getUserRoleToOption({ id: value, name: label, avatar });
    });
  }, [profileListOptions]);

  const groupsOptions = useMemo(() => {
    return usersGroupsOptions.map(({ value, name }) => getGroupRoleToOption({ id: value, name }));
  }, [usersGroupsOptions]);

  const renderItemContent: MultiSelectFieldProps['ItemContent'] = ({ data: { label } }) => <>{label}</>;

  return (
    <>
      <UiForm.Item
        name={permission}
        layout="vertical"
        label={permissionLabel}
        tooltip={{ title: tooltipTitle }}
        initialValue={PermissionSettingsType.Anyone}
      >
        <UiSelect
          placeholder="Выбрать"
          options={permissionTypeOptions}
          getPopupContainer={getPopupContainerParentElement}
        />
      </UiForm.Item>
      <UiForm.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const permissionType = getFieldValue(permission);
          const isShowPermissionFields = permissionType === PermissionSettingsType.Some;

          return (
            <>
              {groups && (
                <UiForm.Item
                  hidden={!isShowPermissionFields}
                  name={groups}
                  label="Выбрать группу или группы пользователей"
                  layout="vertical"
                >
                  <MultiSelectField
                    name={groups}
                    options={groupsOptions}
                    ItemContent={renderItemContent}
                    onSearch={onSearchUsersGroups}
                    getPopupContainer={getPopupContainerParentElement}
                  />
                </UiForm.Item>
              )}
              {users && (
                <UiForm.Item
                  hidden={!isShowPermissionFields}
                  name={users}
                  label="Выбрать конкретных пользователей"
                  layout="vertical"
                >
                  <MultiSelectField
                    name={users}
                    options={userOptions}
                    ItemContent={renderItemContent}
                    onSearch={onSearchProfileList}
                    getPopupContainer={getPopupContainerParentElement}
                  />
                </UiForm.Item>
              )}
            </>
          );
        }}
      </UiForm.Item>
    </>
  );
};
