import { useBreakpoint, UiButton, UiButtonProps, UiSpace, UiSpaceProps } from '@vkph/ui';
import React, { FC } from 'react';

import { useSpace } from '@vkph/ui/hooks';

export type PostFooterAction = Pick<UiButtonProps, 'label' | 'onClick' | 'type' | 'disabled' | 'loading'>;

interface Props extends UiSpaceProps {
  actions: PostFooterAction[];
}

export const PostFooterActions: FC<Props> = (props) => {
  const { actions, ...rest } = props;
  const breakpoints = useBreakpoint();
  const { spaceS } = useSpace();

  return (
    <UiSpace
      full={!breakpoints.lg}
      direction={breakpoints.lg ? 'horizontal' : 'vertical'}
      size={spaceS}
      {...rest}
    >
      {actions.map((action) => (
        <UiButton block={!breakpoints.lg} key={action.label} size="large" {...action} />
      ))}
    </UiSpace>
  );
};
