import {
  UiButton,
  UiFlex,
  UiIcon,
  UiImage,
  UiSpace,
  UiTooltip,
  UiTypography,
  variables,
  notification,
  useSpace,
  usePaddingStyle,
} from '@vkph/ui';
import { isAfter } from 'date-fns';
import React, { CSSProperties, FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GetCalendarEventsStorage } from '@vkph/common/store';
import { confirmEventParticipationEffect, declineEventParticipationEffect } from '@vkph/common/store/events';
import { CalendarEventModel, CalendarEventType } from '@vkph/common/types/models';
import { getErrorResponseMessage, getFormattedDateRange } from '@vkph/common/utils';
import CalendarAddSvg from '@vkph/ui/svg/calendar-add.svg';
import CalendarClearSvg from '@vkph/ui/svg/calendar-clear.svg';
import LocationSvg from '@vkph/ui/svg/location.svg';
import TeamOutlineSvg from '@vkph/ui/svg/team-outline.svg';
import TimeSvg from '@vkph/ui/svg/time.svg';
import VideoCameraOutlineSvg from '@vkph/ui/svg/video-camera-outline.svg';

type AppearanceTooltipProps = {
  children: React.ReactNode;
  event: CalendarEventModel;
  isLastWeekDay?: boolean;
  columnDate?: Date;
  eventsStorage: GetCalendarEventsStorage;
};

export const AppearanceTooltip: FC<AppearanceTooltipProps> = (props) => {
  const { event, children, isLastWeekDay, eventsStorage } = props;
  const {
    id,
    summary,
    since,
    till,
    image,
    place,
    format,
    isUserParticipate,
    memberTotalUids,
    eventType,
    firstAttendees,
  } = event;
  const { fileStorageImageUrl } = image || {};
  const participantsCount =
    eventType === CalendarEventType.Task ? firstAttendees?.length : memberTotalUids?.length;
  const timeRange = getFormattedDateRange(since, till, 'time');
  const isEventPass = useMemo(() => isAfter(new Date(), new Date(till)), [till]);
  const isActivity = eventType === CalendarEventType.Activity;
  const { spaceXS, spaceS, spaceM, spaceL } = useSpace();
  const paddingStyle = usePaddingStyle([spaceL]);
  const fontStyleBody: CSSProperties = { color: variables.themePalette.colorStrokeDark, margin: 0 };
  const { storage } = eventsStorage;
  const { refetchWithLastParams: refetchCalendarEventData } = useAbstractStorage(storage);
  const buttonStyles: CSSProperties = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  };

  const toggleParticipateHandler: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const effect = isUserParticipate
      ? declineEventParticipationEffect(id)
      : confirmEventParticipationEffect({ id, body: { invitedGuestsCount: 0 } });

    effect
      .then(() => {
        refetchCalendarEventData();
      })
      .catch((error) => {
        notification.error({ message: getErrorResponseMessage(error) });
      });
  };

  const tooltipPlacement = isLastWeekDay ? 'left' : undefined;

  const tooltip = (
    <>
      <UiFlex gap={spaceM} style={paddingStyle}>
        {isActivity && (
          <UiFlex>
            <UiImage src={fileStorageImageUrl || undefined} width={96} height={96} />
          </UiFlex>
        )}
        <UiFlex vertical gap={spaceS}>
          <UiTypography.Text strong>{summary}</UiTypography.Text>
          <UiFlex gap={spaceM} align="center">
            <UiIcon.Label component={TimeSvg} height={20} width={20}>
              <UiTypography.Title style={fontStyleBody} level={5}>
                {timeRange}
              </UiTypography.Title>
            </UiIcon.Label>
            <UiIcon.Label component={TeamOutlineSvg} height={20} width={20}>
              <UiTypography.Title style={fontStyleBody} level={5}>
                {participantsCount}
              </UiTypography.Title>
            </UiIcon.Label>
            <UiIcon.Label component={VideoCameraOutlineSvg} height={20} width={20}>
              <UiTypography.Title style={fontStyleBody} level={5}>
                {format}
              </UiTypography.Title>
            </UiIcon.Label>
          </UiFlex>
          {place && (
            <UiFlex gap={spaceXS} align="start">
              <UiIcon.Label strong component={LocationSvg} height={20} width={20}>
                {place}
              </UiIcon.Label>
            </UiFlex>
          )}
        </UiFlex>
      </UiFlex>
      {!isEventPass && isActivity && (
        <UiSpace.Compact block>
          <UiButton
            block
            type={isUserParticipate ? 'action-label-danger' : 'action-label'}
            onClick={toggleParticipateHandler}
            style={buttonStyles}
          >
            <UiIcon
              color={variables.themeVariables.colorTextOnBrand}
              component={isUserParticipate ? CalendarClearSvg : CalendarAddSvg}
              width={20}
              height={20}
            />
            <UiTypography.Text strong>
              {isUserParticipate ? 'Отменить участие' : 'Участвовать'}
            </UiTypography.Text>
          </UiButton>
        </UiSpace.Compact>
      )}
    </>
  );

  return (
    <UiTooltip
      title={tooltip}
      placement={tooltipPlacement}
      overlayInnerStyle={{ minWidth: 480, borderRadius: 8, padding: 0 }}
    >
      {children}
    </UiTooltip>
  );
};
