import {
  UiButton,
  UiButtonProps,
  UiForm,
  UiFieldProps,
  UiIcon,
  UiSelectProps,
  UiOptionData,
  getExcludedOptions,
} from '@vkph/ui';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { AutoCompleteListStorage } from '@vkph/common/types/autoCompleteList';
import AddCircleSvg from '@vkph/ui/svg/add-circle.svg';

import styles from './AutoCompleteListField.scss';
import { AutoCompleteListItemField, AutoCompleteListItemFieldProps } from './Item';

export type AutoCompleteProps = Omit<
  UiSelectProps<UiOptionData>,
  'options' | 'onBlur' | 'onChange' | 'onSelect' | 'onSearch'
>;

export interface AutoCompleteListFieldProps<FP> {
  name: string;
  addButtonProps?: UiButtonProps;
  autoCompleteProps?: Partial<AutoCompleteListItemFieldProps>;
  autoCompleteFieldProps?: UiFieldProps;
  optionsStorage: AutoCompleteListStorage;
  optionsStorageFetchParams?: FP;
  disabled?: boolean;
}

export const AutoCompleteListField = <FP extends Record<string, unknown>>(
  props: AutoCompleteListFieldProps<FP>,
) => {
  const {
    name,
    addButtonProps,
    autoCompleteProps,
    optionsStorage,
    autoCompleteFieldProps,
    disabled,
    optionsStorageFetchParams,
  } = props;
  const [searchValue, setSearchValue] = useState('');

  const { data: optionsData } = useAbstractStorage(optionsStorage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { ...optionsStorageFetchParams, search: searchValue },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const onSearchClear = () => setSearchValue('');
  const onSearchDebounced = useDebouncedCallback(setSearchValue, 300);

  return (
    <UiForm.Item noStyle shouldUpdate>
      {({ getFieldValue }) => (
        <UiForm.List name={name}>
          {(fields, { add, remove }) => (
            <div className={styles.autoCompleteList}>
              {fields.map((field) => (
                <UiForm.Item {...autoCompleteFieldProps} {...field} key={field.key}>
                  <AutoCompleteListItemField
                    size="large"
                    placeholder="Выбрать"
                    options={getExcludedOptions(optionsData, getFieldValue(name), 'value')}
                    onSearchClear={onSearchClear}
                    onSearch={onSearchDebounced}
                    onRemove={() => remove(field.name)}
                    disabled={disabled}
                    {...autoCompleteProps}
                  />
                </UiForm.Item>
              ))}
              <UiButton
                type="link"
                label="Добавить"
                disabled={disabled}
                onClick={() => add()}
                icon={<UiIcon component={AddCircleSvg} width={20} height={20} />}
                {...addButtonProps}
              />
            </div>
          )}
        </UiForm.List>
      )}
    </UiForm.Item>
  );
};
