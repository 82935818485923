import {
  UiButton,
  UiDropdown,
  UiFlex,
  UiIcon,
  UiInput,
  UiPopover,
  UiSpace,
  UiTypography,
  usePaddingStyle,
  useSpace,
  useToggle,
  notification,
  UiDnD,
  dndClosesCenterCollision,
  UiDnDUniqueIdentifier,
  UiDnDDragStartEvent,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import map from 'lodash/map';
import React, { ChangeEvent, FC, RefObject, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { GetListColumnsStorage } from '@vkph/common/store/lists';
import { ListColumnModel, ListId } from '@vkph/common/types/models';
import { UiDnDDragEndEvent } from '@vkph/ui/components/dnd';
import {
  dndVerticalListSortingStrategy,
  getDnDDragEndContainersArray,
  snapDnDItemToCursor,
} from '@vkph/ui/components/dnd/utils';
import AddSvg from '@vkph/ui/svg/add.svg';
import DropDownSvg from '@vkph/ui/svg/drop-down.svg';
import DropUpSvg from '@vkph/ui/svg/drop-up.svg';
import MeatballSvg from '@vkph/ui/svg/meatball.svg';

import { GetColumnDropdownActionItems, GetCreateColumnItems } from '../DynamicTable';
import { MAX_COLUMNS_COUNT } from '../constants';
import { ListCellItem } from './cell-item/ListCellItem';

export interface DynamicTableSettingsProps {
  listId: ListId;
  listColumnsStorage: GetListColumnsStorage;
  getColumnDropdownActionItems: GetColumnDropdownActionItems;
  getCreateColumnItems: GetCreateColumnItems;
  containerRef?: RefObject<HTMLDivElement>;
}

enum ColumnStatus {
  Visible = 'visible',
  Hidden = 'hidden',
}

const containers = [ColumnStatus.Visible, ColumnStatus.Hidden];

const containerTitleMap = {
  [ColumnStatus.Visible]: 'Показано в списке',
  [ColumnStatus.Hidden]: 'Скрыто в списке',
};

const getColumnsDifference = (columnsArray: ListColumnModel[], changedColumnsArray: ListColumnModel[]) => {
  return columnsArray
    .filter((x) => !changedColumnsArray.includes(x))
    .concat(changedColumnsArray.filter((x) => !columnsArray.includes(x)));
};

export const DynamicTableSettings: FC<DynamicTableSettingsProps> = (props) => {
  const { listId, listColumnsStorage, containerRef, getColumnDropdownActionItems, getCreateColumnItems } =
    props;
  const [isOpen, toggleIsOpen] = useToggle([false, true]);
  const [search, setSearch] = useState('');
  const [activeListColumnId, setActiveListColumnId] = useState<UiDnDUniqueIdentifier | null>(null);
  const [columnsContainers, setColumnsContainers] = useState<Record<ColumnStatus, ListColumnModel[]>>({
    [ColumnStatus.Visible]: [],
    [ColumnStatus.Hidden]: [],
  });

  const { spaceM, spaceXS, spaceXL } = useSpace();
  const createButtonPadding = usePaddingStyle([spaceM, 0]);
  const titlePadding = usePaddingStyle([spaceXS, 0]);
  const { padding: listPadding } = usePaddingStyle([0, spaceXL]);

  const { toggleVisibleListColumnEffect, updateListColumnsOrderEffect } = listColumnsStorage;
  const { data: columnsData } = useStore(listColumnsStorage.storage.store);

  const activeColumn = useMemo(() => {
    if (activeListColumnId) {
      return columnsData.find((column) => column.id === activeListColumnId);
    }

    return null;
  }, [activeListColumnId, columnsData]);

  const isShowHiddenColumns = activeColumn !== null || columnsContainers[ColumnStatus.Hidden].length > 0;
  const isCreateColumnDisabled = columnsData.length === MAX_COLUMNS_COUNT;

  const onSearchDebounced = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    300,
  );

  const onToggleVisibleColumn = (column: ListColumnModel) => {
    const { id: columnId, isVisible } = column;

    return toggleVisibleListColumnEffect({ id: listId, columnId }).then(() => {
      notification.success({
        message: isVisible ? 'Столбец успешно скрыт' : 'Столбец показан',
        ...(isVisible && {
          description: 'Столбец скрыт в списке и значения в нём стали необязательными',
        }),
      });
    });
  };

  const onReorderColumnsContainers = (containersData: Record<ColumnStatus, ListColumnModel[]>) => {
    const columnsIds = containers.map((status) => map(containersData[status], 'id')).flat();

    return updateListColumnsOrderEffect({ id: listId, order: columnsIds });
  };

  const onDragEnd = (event: UiDnDDragEndEvent<ListColumnModel>) => {
    const reorderedColumnsContainers = getDnDDragEndContainersArray(event, columnsContainers, 'id');

    const [changedColumn] = getColumnsDifference(
      columnsContainers[ColumnStatus.Hidden],
      reorderedColumnsContainers[ColumnStatus.Hidden],
    );

    if (changedColumn) {
      onToggleVisibleColumn(changedColumn);
    }

    onReorderColumnsContainers(reorderedColumnsContainers);

    setColumnsContainers(reorderedColumnsContainers);
    setActiveListColumnId(null);
  };

  const onDragStart = (event: UiDnDDragStartEvent<ListColumnModel>) => {
    setActiveListColumnId(event.active.id);
  };

  useEffect(() => {
    const sortedColumns = columnsData
      .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
      .reduce<Record<ColumnStatus, ListColumnModel[]>>(
        (acc, column) => {
          if (column.isVisible) {
            acc[ColumnStatus.Visible].push(column);
          } else {
            acc[ColumnStatus.Hidden].push(column);
          }

          return acc;
        },
        {
          [ColumnStatus.Visible]: [],
          [ColumnStatus.Hidden]: [],
        },
      );

    setColumnsContainers(sortedColumns);
  }, [columnsData, search, isOpen]);

  useEffect(() => {
    setSearch('');
  }, [isOpen]);

  const ListCellItems: FC<{ columns: ListColumnModel[] }> = (args) => {
    const { columns } = args;

    return columns.map((column, index) => (
      <ListCellItem
        key={column.id}
        column={column}
        disableDrag={Boolean(search)}
        onVisibleToggle={() => onToggleVisibleColumn(column)}
        extra={
          column.isVisible && (
            <UiDropdown
              trigger={['click']}
              placement="bottomRight"
              getPopupContainer={() => containerRef?.current || document.body}
              dropdownRender={(menu) => (
                <UiButton.Decorator onClick={() => toggleIsOpen(false)}>{menu}</UiButton.Decorator>
              )}
              menu={{
                items: getColumnDropdownActionItems(column, index),
              }}
            >
              <UiButton
                icon={<UiIcon width={20} height={20} component={MeatballSvg} />}
                type="link-secondary"
              />
            </UiDropdown>
          )
        }
      />
    ));
  };

  return (
    <UiPopover
      open={isOpen}
      onOpenChange={toggleIsOpen}
      trigger="click"
      placement="bottomRight"
      destroyTooltipOnHide
      getPopupContainer={() => containerRef?.current || document.body}
      arrow={false}
      overlayInnerStyle={{ padding: 0 }}
      content={
        <UiFlex vertical style={{ width: '460px', maxHeight: '524px' }}>
          <UiFlex vertical gap={spaceM} style={{ padding: spaceXL }}>
            <UiTypography.Title level={3}>Столбцы</UiTypography.Title>
            <UiInput.Search allowClear placeholder="Поиск" onChange={onSearchDebounced} />
          </UiFlex>
          <UiFlex
            vertical
            gap={spaceXS}
            style={{ padding: listPadding, marginBottom: spaceM, flex: 1, overflow: 'auto' }}
          >
            <UiDnD.Context
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              collisionDetection={dndClosesCenterCollision}
              modifiers={[snapDnDItemToCursor({ placement: 'right', horizontalShift: spaceXL })]}
            >
              {[ColumnStatus.Visible, ColumnStatus.Hidden].map((containerKey) => (
                <UiFlex vertical key={containerKey}>
                  {(containerKey !== ColumnStatus.Hidden || isShowHiddenColumns) && (
                    <>
                      <UiTypography.Text strong style={titlePadding}>
                        {containerTitleMap[containerKey]}
                      </UiTypography.Text>
                      <UiFlex vertical>
                        <UiDnD.Droppable
                          id={containerKey}
                          disabled={columnsContainers[containerKey].length > 0}
                        >
                          <UiDnD.Sortable.Context
                            items={map(columnsContainers[containerKey], 'id')}
                            strategy={dndVerticalListSortingStrategy}
                          >
                            <ListCellItems columns={columnsContainers[containerKey]} />
                          </UiDnD.Sortable.Context>
                        </UiDnD.Droppable>
                      </UiFlex>
                    </>
                  )}

                  {containerKey === ColumnStatus.Visible && (
                    <UiSpace style={createButtonPadding}>
                      <UiDropdown
                        trigger={['click']}
                        getPopupContainer={() => containerRef?.current || document.body}
                        disabled={isCreateColumnDisabled}
                        dropdownRender={(menu) => (
                          <UiButton.Decorator onClick={() => toggleIsOpen(false)}>{menu}</UiButton.Decorator>
                        )}
                        menu={{
                          items: getCreateColumnItems({ showTooltip: false }),
                        }}
                      >
                        <UiButton
                          type="link"
                          icon={<UiIcon width={20} height={20} component={AddSvg} />}
                          label="Добавить столбец"
                        />
                      </UiDropdown>
                    </UiSpace>
                  )}
                </UiFlex>
              ))}

              <UiDnD.Overlay>
                {activeColumn && (
                  <UiDnD.Overlay.Item>
                    <ListCellItem column={activeColumn} />
                  </UiDnD.Overlay.Item>
                )}
              </UiDnD.Overlay>
            </UiDnD.Context>
          </UiFlex>
        </UiFlex>
      }
    >
      <UiButton type="primary" size="large">
        Настроить список
        <UiIcon width={20} height={20} component={isOpen ? DropUpSvg : DropDownSvg} />
      </UiButton>
    </UiPopover>
  );
};
