import { Command } from '@ckeditor/ckeditor5-core';
import { Notification } from '@ckeditor/ckeditor5-ui';
import { FileLoader, FileRepository } from '@ckeditor/ckeditor5-upload';
import { toMap } from '@ckeditor/ckeditor5-utils';

import { FileUploadProgress } from '../file-upload-progress';
import { addMentionAttributes } from '../mention/lib/utils';
import { UploadAdapterResponse } from '../upload-adapter';

export class SimpleFileUploadCommand extends Command {
  refresh() {
    this.isEnabled = true;
  }

  execute(options: { file: File[] }) {
    const { editor } = this;

    const fileRepository = editor.plugins.get(FileRepository);
    const filesToUpload = options.file;

    for (const file of filesToUpload) {
      const loader = fileRepository.createLoader(file);

      this.readAndUpload(loader, file);
    }
  }

  readAndUpload(loader: FileLoader, file: File) {
    const { editor } = this;
    const { model, plugins } = editor;
    const fileRepository = plugins.get(FileRepository);
    const notification = plugins.get(Notification);

    const clean = () => {
      fileRepository.destroyLoader(loader);
    };

    loader
      .read()
      .then(() => {
        FileUploadProgress.createUiBar(editor, {
          uploadId: loader.id,
          name: file.name,
        });
        return loader.upload();
      })
      .then((data) => {
        const { urls, fileId } = data as UploadAdapterResponse;
        const { selection } = model.document;
        const fileName = file.name;

        const range = selection.getFirstRange();
        // TODO: вынести общую логику в отдельный плагин (B2BCORE-10506)
        const mention = addMentionAttributes({ id: fileId, _text: fileName }, { link: urls.default, fileId });

        model.change((writer) => {
          const currentAttributes = toMap(selection.getAttributes());
          const attributesWithMention = new Map(currentAttributes.entries());

          attributesWithMention.set('mention', mention);
          editor.model.insertContent(writer.createText(fileName, attributesWithMention), range);

          clean();
        });
      })
      .catch((error) => {
        if (loader.status !== 'error' && loader.status !== 'aborted') {
          throw error;
        }

        if (loader.status === 'error' && error) {
          notification.showWarning(error, {
            title: 'Ошибка загрузки файла',
            namespace: 'upload',
          });
        }

        clean();
      });
  }
}
