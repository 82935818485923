import {
  UserProfileActions,
  UserProfileActionsProps as UserProfileActionsPropsImport,
} from './actions/UserProfileActions';
import { UserProfileInfo, UserProfileInfoProps as UserProfileInfoPropsImport } from './info/UserProfileInfo';
import {
  UserProfileSkeleton,
  UserProfileSkeletonProps as UserProfileSkeletonPropsImport,
} from './skeleton/UserProfileSkeleton';
import {
  UserProfileTooltip,
  UserProfileTooltipProps as UserProfileTooltipPropsImport,
} from './tooltip/UserProfileTooltip';

export type UserProfileInfoProps = UserProfileInfoPropsImport;
export type UserProfileTooltipProps = UserProfileTooltipPropsImport;
export type UserProfileActionsProps = UserProfileActionsPropsImport;
export type UserProfileSkeletonProps = UserProfileSkeletonPropsImport;

export type UserProfileComposition = {
  Actions: typeof UserProfileActions;
  Info: typeof UserProfileInfo;
  Skeleton: typeof UserProfileSkeleton;
  Tooltip: typeof UserProfileTooltip;
};

export const UserProfile: UserProfileComposition = {
  Actions: UserProfileActions,
  Info: UserProfileInfo,
  Skeleton: UserProfileSkeleton,
  Tooltip: UserProfileTooltip,
};
