import { UiInput, UiInputProps, UiInputRef } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import { POLL_BUTTON_TYPE_DEFAULT } from '../../constants';
import { PollButton, PollButtonProps, PollButtonTypesProps } from '../PollButton';
import styles from './PollButtonInput.scss';

export interface PollButtonInputProps extends Omit<UiInputProps, 'type'> {
  type?: PollButtonTypesProps['type'];
  inputRef?: React.Ref<UiInputRef>;
  buttonProps?: PollButtonProps;
}

const DEFAULT_BUTTON_PROPS = {
  type: POLL_BUTTON_TYPE_DEFAULT,
};

export const PollButtonInput: FC<PollButtonInputProps> = (pollButtonInputProps) => {
  const { placeholder, inputRef, className, buttonProps, ...props } = pollButtonInputProps;

  return (
    <PollButton className={styles.pollButtonInput} {...DEFAULT_BUTTON_PROPS} {...buttonProps}>
      <UiInput
        {...props}
        ref={inputRef}
        variant="borderless"
        placeholder={placeholder || 'Введите вариант ответа'}
        className={classNames(styles.pollButtonInput__input, className)}
      />
    </PollButton>
  );
};
