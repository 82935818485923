import {
  UiButton,
  UiCard,
  UiCol,
  UiRow,
  UiIcon,
  UiIconProps,
  UiSpace,
  UiSpinner,
  UiTypography,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { GetProjectStagesStorage } from '@vkph/common/store/tasks';
import { variables } from '@vkph/ui/providers/theme';
import ArrowBackSvg from '@vkph/ui/svg/arrow-back.svg';
import ArrowFrontSvg from '@vkph/ui/svg/arrow-front.svg';
import FullscreenSvg from '@vkph/ui/svg/fullscreen.svg';

import { GanttCompact } from '../compact/GanttCompact';
import { GANTT_BASE_NAVIGATION_WIDTH, GANTT_PREVIEW_CONTAINER_SIZE } from '../constants';

interface GanttPreviewProps {
  storages: GetProjectStagesStorage;
  onOpenFullSize: () => void;
}

export const GanttPreview: FC<GanttPreviewProps> = (props) => {
  const { storages, onOpenFullSize } = props;
  const { storage, paramsStore } = storages;
  const isProjectStagesLoading = useStore(storage.fetchEffect.pending);
  const year = storage.getLastRequestParams()?.year || 0;

  const onPlusYear = () => paramsStore.updateStoreEvent({ year: year + 1 });
  const onMinusYear = () => paramsStore.updateStoreEvent({ year: year - 1 });

  const btnIconProps: UiIconProps = {
    width: 20,
    height: 20,
    color: variables.themePalette.colorIcon,
  };

  return (
    <UiSpinner spinning={isProjectStagesLoading}>
      <UiCard size="default" emptyPadding>
        <UiRow wrap={false}>
          <UiRow align="top">
            <UiRow padding={[32, 20]} gutter={[24, 24]} style={{ width: GANTT_BASE_NAVIGATION_WIDTH }}>
              <UiCol span={24}>
                <UiSpace full direction="vertical" align="center">
                  <UiTypography.Text type="secondary" strong>
                    Этапы проекта
                  </UiTypography.Text>
                </UiSpace>
              </UiCol>
              <UiCol span={24}>
                <UiRow justify="space-between">
                  <UiButton
                    type="link-secondary"
                    icon={<UiIcon component={ArrowBackSvg} {...btnIconProps} />}
                    onClick={onMinusYear}
                    disabled={isProjectStagesLoading}
                  />
                  <UiTypography.Title level={3}>{year}</UiTypography.Title>
                  <UiButton
                    type="link-secondary"
                    icon={<UiIcon component={ArrowFrontSvg} {...btnIconProps} />}
                    onClick={onPlusYear}
                    disabled={isProjectStagesLoading}
                  />
                </UiRow>
              </UiCol>
              <UiCol span={24}>
                <UiButton
                  label="На весь экран"
                  type="tertiary"
                  block
                  size="small"
                  icon={<UiIcon component={FullscreenSvg} {...btnIconProps} />}
                  onClick={onOpenFullSize}
                  disabled={isProjectStagesLoading}
                />
              </UiCol>
            </UiRow>
          </UiRow>
          <UiRow style={GANTT_PREVIEW_CONTAINER_SIZE}>
            <GanttCompact year={year} storage={storage} />
          </UiRow>
        </UiRow>
      </UiCard>
    </UiSpinner>
  );
};
