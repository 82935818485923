import { differenceInCalendarDays, eachDayOfInterval, format, isSameDay } from 'date-fns';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  appearanceDatesStorage,
  GetCalendarEventsStorage,
  GetCalendarSelectedStorage,
} from '@vkph/common/store/calendar';
import { NotificationPayloads, NotificationTypes, useNotificationEvent } from '@vkph/common/utils';

import styles from './AppearanceMonth.scss';
import { MonthEvent } from './event/MonthEvent';
import { MonthFill } from './fill/MonthFill';
import { MonthHeading } from './heading/MonthHeading';

type Props = {
  eventsStorage: GetCalendarEventsStorage;
  selectedStorage: GetCalendarSelectedStorage;
};

const MAX_COUNT_SHOW_EVENTS = 5;

export const AppearanceMonth: FC<Props> = (props) => {
  const { eventsStorage, selectedStorage } = props;
  const { addNotificationEvent } = eventsStorage;
  const { eventTypesFilterStore } = selectedStorage;

  const { data: reducedEvents } = useAbstractStorage(eventsStorage.storage);

  const { sinceDate, tillDate } = useStore(appearanceDatesStorage.store);
  const eventTypesFilter = useStore(eventTypesFilterStore);

  const notificationListener = (newEvent: NotificationPayloads[NotificationTypes.CalendarEventCreated]) => {
    addNotificationEvent(newEvent.event);
  };

  useNotificationEvent(NotificationTypes.CalendarEventCreated, notificationListener);

  const daysOfMonth = eachDayOfInterval({ start: sinceDate, end: tillDate });

  const monthFillEls = daysOfMonth?.map((dayOfMonth, index) => {
    const keyOfReducedEvents = format(dayOfMonth, 'yyyy.MM.dd');
    const eventsOnDay = reducedEvents[keyOfReducedEvents] || [];
    const isDayOff = (index + 1) % 7 === 0 || (index + 2) % 7 === 0;

    const eventsElements = eventsOnDay.map((event) => {
      const isSameEvent = differenceInCalendarDays(new Date(event.till), new Date(event.since)) > 0;
      const isLastDayEvent = isSameDay(dayOfMonth, new Date(event.till));
      const isFirstDayEvent = isSameDay(dayOfMonth, new Date(event.since)) || index % 7 === 0;
      const isEventTypesSelectedStore = eventTypesFilter.indexOf(event.eventType) > -1;

      if (!isEventTypesSelectedStore) {
        return null;
      }

      return (
        <MonthEvent
          isSameTime={Boolean(isSameEvent)}
          isLastDayEvent={isLastDayEvent}
          isFirstDayEvent={isFirstDayEvent}
          key={event.id}
          event={event}
          columnDate={dayOfMonth}
          eventsStorage={eventsStorage}
          isLastWeekDay={(index + 1) % 7 === 0}
        />
      );
    });

    return (
      <MonthFill
        key={keyOfReducedEvents}
        dayOfMonth={dayOfMonth}
        isDayOff={isDayOff}
        eventsStorage={eventsStorage}
        extra={eventsElements.slice(MAX_COUNT_SHOW_EVENTS)}
      >
        {eventsElements.slice(0, MAX_COUNT_SHOW_EVENTS - 1)}
      </MonthFill>
    );
  });

  return (
    <div className={styles.appearanceMonth}>
      <MonthHeading />
      <div className={styles.appearanceMonth__content}>{monthFillEls}</div>
    </div>
  );
};
