import { Command, type Editor } from '@ckeditor/ckeditor5-core';
import type { ImageUtils } from '@ckeditor/ckeditor5-image';
import { FileRepository, UploadResponse } from '@ckeditor/ckeditor5-upload';
import { toArray, ArrayOrItem } from '@ckeditor/ckeditor5-utils';
import { notification } from '@vkph/ui';

import { FileStorageEntryId } from '@vkph/common/types/models';
import { readFileToStringPromise } from '@vkph/common/utils';

import { editorConfigurationCrop } from '../../config/constants';
import { FileUploadProgress } from '../file-upload-progress';
import { cropAndInsertImage } from './utils';

export class ImageCropCommand extends Command {
  public declare isAccessAllowed: boolean;

  constructor(editor: Editor) {
    super(editor);

    this.set('isAccessAllowed', true);
  }

  public override refresh(): void {
    const { editor } = this;
    const imageUtils: ImageUtils = editor.plugins.get('ImageUtils');
    const selectedElement = editor.model.document.selection.getSelectedElement()!;

    this.isEnabled = imageUtils.isImageAllowed() || imageUtils.isImage(selectedElement);
  }

  public override execute(options: { file: ArrayOrItem<File> }): void {
    const files = toArray(options.file);

    files.forEach((file) => {
      this.uploadImage(file);
    });
  }

  private uploadImage(file: File): void {
    const { editor } = this;
    const fileRepository = editor.plugins.get(FileRepository);
    const loader = fileRepository.createLoader(file);

    loader
      .read()
      .then(() => {
        FileUploadProgress.createUiBar(editor, {
          uploadId: loader.id,
          name: file.name,
        });
        return loader.upload();
      })
      .then((data) => {
        const urls = data.urls as UploadResponse;
        const fileId = data.fileId as FileStorageEntryId;
        const fileUrl = (urls.default as string) || '';
        const cropConfig = editor.config.get('crop') || editorConfigurationCrop;

        readFileToStringPromise(file, true)
          .then((preview) => {
            return cropAndInsertImage({
              editor,
              cropConfig,
              value: {
                uploadImage: {
                  file: fileUrl,
                  id: fileId,
                },
                previewImage: preview,
              },
            });
          })
          .catch(() => notification.error({ message: 'Не удалось считать приложенное изображение' }));
      })
      ?.catch(() => {
        notification.error({ message: 'Не удалось загрузить изображение' });
      });
  }
}
