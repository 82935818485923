import React, { FC, PropsWithChildren } from 'react';

import { SurveyQuestionInfo, SurveyQuestionInfoProps } from '../survey-question-info/SurveyQuestionInfo';
import { SurveyQuestion, SurveyQuestionProps } from '../survey-question/SurveyQuestion';

export type SurveyQuestionChartProps = SurveyQuestionProps & SurveyQuestionInfoProps;

export const SurveyQuestionChart: FC<PropsWithChildren<SurveyQuestionChartProps>> = (props) => {
  const { title, questionInfo, className, children, ...rest } = props;

  return (
    <SurveyQuestion className={className} title={title} {...rest}>
      <SurveyQuestionInfo questionInfo={questionInfo} />
      {children}
    </SurveyQuestion>
  );
};
