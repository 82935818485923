import { CropConfig } from '../types';

export const defaultEditorAspectSettings = {
  width: 15,
  height: 10,
};

export const editorConfigurationCrop: CropConfig = {
  aspect: defaultEditorAspectSettings.width / defaultEditorAspectSettings.height,
  minWidth: defaultEditorAspectSettings.width * 10,
  minHeight: defaultEditorAspectSettings.height * 10,
};
