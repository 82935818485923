import { UiCell } from '@vkph/ui';
import React from 'react';

import { UserAvatarModel, UserIdModel } from '@vkph/common/types/models';
import { OptionModel } from '@vkph/ui/types/option';

import { MultiSelectAvatarLabelDataType } from '../../components/multi-select-avatar-label';
import { UserAvatar } from '../../components/user-avatar';

interface UserRoleToOptionParams extends Pick<UserAvatarModel, 'avatar'> {
  name: string;
  id: UserIdModel;
}

export const getUserRoleToOption = (
  user: UserRoleToOptionParams,
): OptionModel<UserRoleToOptionParams['id'], MultiSelectAvatarLabelDataType> => {
  const { name, id, avatar } = user;

  return {
    value: id,
    data: {
      avatar,
      label: <UiCell avatar={<UserAvatar size="extraSmall" src={avatar} />} title={name} />,
      selectedLabel: name,
    },
  };
};
