import { addHours } from 'date-fns';
import React, { FC, MouseEventHandler } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GetCalendarEventsStorage } from '@vkph/common/store/calendar';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';

import { Calendar } from '../../../calendar';

interface Props {
  hour: number;
  isSidebar?: boolean;
  columnDate?: Date;
  eventsStorage: GetCalendarEventsStorage;
}

export const ColumnFill: FC<Props> = (props) => {
  const { hour, isSidebar, columnDate, eventsStorage } = props;

  if (isSidebar) {
    return <Calendar.TimeCell hour={hour} />;
  }

  const { refetchWithLastParams } = useAbstractStorage(eventsStorage.storage);
  const { siteId } = eventsStorage.storage.getLastRequestParams() || {};

  const onHourOfDayClickHandler: MouseEventHandler<HTMLElement> = (event) => {
    event.currentTarget.blur();
    openGlobalModal(GlobalModalNames.CalendarCreateUpdateMeeting, {
      initialEventFormValues: {
        sinceDate: columnDate ? addHours(columnDate, hour) : undefined,
        tillDate: columnDate ? addHours(columnDate, hour + 1) : undefined,
      },
      onSuccess: refetchWithLastParams,
      siteId,
    });
  };

  return <Calendar.Cell onClick={onHourOfDayClickHandler} />;
};
