export enum PermissionSettingsType {
  Anyone = 'anyone',
  Some = 'some',
  Nobody = 'nobody',
}

export const PERMISSION_SETTINGS_SITE_PERMISSION_TYPE_LABELS = {
  [PermissionSettingsType.Anyone]: 'Все пользователи сайта',
  [PermissionSettingsType.Some]: 'Некоторые пользователи сайта',
  [PermissionSettingsType.Nobody]: 'Никто из пользователей',
};
