import { UiForm, UiTooltip, useSpace } from '@vkph/ui';
import React, { FC, useEffect } from 'react';

import { validURLWithProtocolRule } from '@vkph/common/utils';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';

type Props = DynamicTableCellContentProps<string>;

const MAX_LENGTH = 21;

export const LinkCell: FC<Props> = (props) => {
  const { value, dataIndex, readOnly, onFinish, inputSuffix } = props;
  const [form] = UiForm.useForm();
  const { spaceXL } = useSpace();

  const initialValues = {
    [dataIndex]: value,
  };

  const onSubmit = () => {
    form.submit();
  };

  const onSave = () => {
    onSubmit();
  };

  useEffect(() => {
    form.setFieldValue([dataIndex], value);
  }, [value]);

  return (
    <UiForm initialValues={initialValues} onFinish={onFinish} form={form}>
      <UiTooltip
        placement="top"
        shift={spaceXL}
        title={value && value.toString().length > MAX_LENGTH ? value : null}
      >
        <UiForm.Item style={{ marginBottom: 0 }} name={dataIndex} rules={[validURLWithProtocolRule]}>
          <CellInput.Link
            onPressEnter={onSave}
            onBlur={readOnly ? undefined : onSave}
            placeholder={readOnly ? undefined : 'Добавить ссылку'}
            readOnly={readOnly}
            suffix={inputSuffix}
          />
        </UiForm.Item>
      </UiTooltip>
    </UiForm>
  );
};
