import React, { HTMLAttributes, useContext } from 'react';

import { convertMinutesToPx } from '@vkph/common/utils';

import { CalendarAppearancePermissionsContext } from '../../calendar-appearance/context';
import styles from './CalendarCell.scss';

interface CalendarCellProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  height?: number;
}

export type CalendarCellPropsComponent = React.FC<CalendarCellProps>;

export const CalendarCell: CalendarCellPropsComponent = (props) => {
  const { onClick, height = convertMinutesToPx(60) } = props;
  const { readOnly } = useContext(CalendarAppearancePermissionsContext);

  const commonProps: HTMLAttributes<HTMLElement> = {
    style: {
      height,
    },
    className: styles.calendarCell,
  };

  return readOnly ? <div {...commonProps} /> : <button {...commonProps} type="button" onClick={onClick} />;
};
