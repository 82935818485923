import {
  ColumnsType,
  UiButton,
  UiIcon,
  notification,
  useFormBlocker,
  useModalBase,
  useToggle,
  UiRender,
  UiRenderType,
  UiTable,
  UiDropdown,
  UiTooltip,
  UiFlex,
  useSpace,
  UiDropdownProps,
  UiTableProps,
} from '@vkph/ui';
import classNames from 'classnames';
import { useStore, useStoreMap } from 'effector-react';
import merge from 'lodash/merge';
import React, { FC, useState, useMemo, Key, useCallback, ReactNode } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  defaultValueCell,
  getListColumnsStorage,
  getListRowsStorage,
  GetListRowsParams,
} from '@vkph/common/store/lists';
import {
  ListColumnFieldType,
  ListColumnId,
  ListColumnModel,
  ListModel,
  ListRowId,
  ListRowModel,
  ListRowValues,
} from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import AddSvg from '@vkph/ui/svg/add.svg';
import ArrowBackSvg from '@vkph/ui/svg/arrow-back.svg';
import ArrowFrontSvg from '@vkph/ui/svg/arrow-front.svg';
import CopyFilledSvg from '@vkph/ui/svg/copy-filled.svg';
import DeleteSvg from '@vkph/ui/svg/delete.svg';
import DropDownSvg from '@vkph/ui/svg/drop-down.svg';
import DropUpSvg from '@vkph/ui/svg/drop-up.svg';
import GearSvg from '@vkph/ui/svg/gear.svg';
import HideSvg from '@vkph/ui/svg/hide.svg';
import ViewSvg from '@vkph/ui/svg/view.svg';

import { ActionsDropdown, ActionsDropdownItem } from '../actions-dropdown';
import styles from './DynamicTable.scss';
import { DynamicTableCell, ListRowValuesWithId, normalizeValuesRow } from './cell';
import {
  CREATE_COLUMNS_ID,
  DynamicTablePermission,
  listColumnTypeFields,
  MAX_COLUMNS_COUNT,
} from './constants';
import {
  CreateUpdateColumn,
  BooleanFormFields,
  DatetimeFormFields,
  EnumFormFields,
  FileFormFields,
  HyperlinkFormFields,
  NumericFormFields,
  TextFormFields,
  DictionaryFormFields,
  UserFormFields,
} from './create-update-column';
import { DynamicTableFilters } from './filters/DynamicTableFilters';
import { TableHeaderCell } from './header-cell/TableHeaderCell';
import { TableHeaderCellTitle } from './header-cell/title/TableHeaderCellTitle';
import { DynamicTableSettings, DynamicTableSettingsProps } from './list-settings/ListSettings';
import { RowDetails } from './row-details/RowDetails';
import { DynamicTableSortButton } from './sort-button/DynamicTableSortButton';

export interface CreateColumnItem {
  id: string;
  name: string;
  fieldType: ListColumnFieldType;
  columnIndex?: number;
}

export type DynamicTableProps = {
  list: ListModel;
  rowsParams?: Partial<GetListRowsParams>;
  permissions: DynamicTablePermission[];
  // TODO:: Избавиться от этого в задаче https://jira.vk.team/browse/B2BCORE-11050
  // Пока не добавляем для пользовательского режима редактирования formBlocker
  // так как иначе у них постоянно будет выходить назойливый formBlocker на любой клик извне
  adminTable?: boolean;
  renderHeader?: (options: DynamicTableSettingsProps) => ReactNode;
};

export type GetColumnDropdownActionItems = (
  column: ListColumnModel,
  index: number,
  options?: {
    isTable?: boolean;
  },
) => Required<UiDropdownProps>['menu']['items'];

export type GetCreateColumnItems = (params?: {
  columnIndex?: number;
  showTooltip?: boolean;
}) => Required<UiDropdownProps>['menu']['items'];

type DynamicTableComposition = {
  Settings: typeof DynamicTableSettings;
};

interface ListRowGroup {
  id: ListRowId;
  rowValues: ListRowValues;
  children?: ListRowValuesWithId[];
}

type DynamicTableRow = ListRowValuesWithId & {
  children?: ListRowValuesWithId[];
};

const cancelModalProps = {
  title: 'Выйти из режима создания списка?',
  content: 'Вы уходите со страницы создания списка, все несохраненные данные могут быть утеряны.',
  okText: 'Выйти',
  cancelText: 'Отмена',
  centered: true,
};

const getDeleteModalProps = (isBatching: boolean) => ({
  title: `Вы уверены, что хотите удалить ${isBatching ? 'выбранные строки' : 'эту строку'}?`,
  content: `Все данные ${isBatching ? 'строк' : 'строки'} будут потеряны без возможности восстановления`,
  okText: `Удалить ${isBatching ? 'строки' : 'строку'}`,
  cancelText: 'Отменить удаление',
});

const getSuccessMessageDeleteRow = (isBatching: boolean) =>
  isBatching ? 'Строки успешно удалены' : 'Строка успешно удалена';

const getErrorMessageDeleteRow = (isBatching: boolean) =>
  isBatching ? 'Не удалось удалить строки' : 'Не удалось удалить строку';

const getReorderedIds = <T extends string | number>(ids: T[], id: T, index: number) => {
  const reorderedList = [...ids];
  const idIndex = reorderedList.indexOf(id);

  if (idIndex !== -1) {
    reorderedList.splice(idIndex, 1);
  }

  reorderedList.splice(index, 0, id);

  return reorderedList;
};

export const isColumnExist = (column: ListColumnModel | CreateColumnItem): column is ListColumnModel => {
  return column.id !== CREATE_COLUMNS_ID;
};

const getSortedColumnsIds = (
  listColumns: ListColumnModel[],
  tableColumns: ColumnsType<ListRowValuesWithId>,
) => {
  const columnsMap = new Map<ListColumnId, ListColumnModel>(listColumns.map((column) => [column.id, column]));

  const sortedIds: ListColumnId[] = [];

  tableColumns.forEach(({ key }) => {
    if (key && typeof key === 'string' && columnsMap.has(key)) {
      sortedIds.push(key);
      columnsMap.delete(key);
    }
  });

  const restIds = Array.from(columnsMap, ([key]) => key);

  return sortedIds.concat(restIds);
};

const getTableRows = (rows: ListRowModel[]) => {
  const rowsDataMap = new Map<string, ListRowGroup>();

  rows.forEach((row) => {
    const { id, group, rowValues } = row;

    if (!group) {
      rowsDataMap.set(id, { id, rowValues });
    }

    if (group) {
      const groupId = group.id;
      const rowChildren = {
        id,
        ...rowValues,
      };

      if (rowsDataMap.has(groupId)) {
        rowsDataMap.get(groupId)?.children?.push(rowChildren);
      } else {
        rowsDataMap.set(groupId, {
          rowValues,
          id: groupId,
          children: [rowChildren],
        });
      }
    }
  });

  return Array.from(rowsDataMap, ([_, { id, rowValues, children }]) => merge({ id, children }, rowValues));
};

export const DynamicTable: FC<DynamicTableProps> & DynamicTableComposition = (props) => {
  const { list, rowsParams, permissions = [], adminTable = false, renderHeader } = props;
  const { id: listId, siteId } = list;

  const permissionMap = useMemo(() => {
    return new Map(permissions.map((permission) => [permission, permission]));
  }, [permissions]);

  const readOnly = permissionMap.size === 0;

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
  const [isBlocked, toggleIsBlocked] = useToggle([true, false]);
  const { confirm, warning } = useModalBase();
  const { spaceL, spaceXS, space3XL } = useSpace();
  const [currentIdRowDetails, setCurrentIdRowDetails] = useState('');

  const listColumnsStorage = useMemo(getListColumnsStorage, []);

  const {
    copyListColumnEffect,
    updateListColumnsOrderEffect,
    deleteListColumnEffect,
    toggleVisibleListColumnEffect,
  } = listColumnsStorage;

  const listRowsStorage = useMemo(getListRowsStorage, []);

  const {
    createRowEffect,
    deleteListRowEffect,
    deleteListRowsBatchEffect,
    updateListRowEffect,
    paramsStore,
  } = listRowsStorage;

  const isBlockedForm = adminTable && isBlocked && !currentIdRowDetails;

  const ref = useFormBlocker<HTMLDivElement>(isBlockedForm, 'confirm', cancelModalProps);

  const [createColumnState, setCreateColumnState] = useState<CreateColumnItem | null>(null);
  const [editColumnId, setEditColumnId] = useState<ListColumnId | null>(null);
  const [columnsOptimisticOrder, setColumnsOptimisticOrder] = useState<string[] | null>(null);

  const rowsParamsData = useStore(paramsStore.store);

  const onCancelColumnCreate = () => {
    setCreateColumnState(null);
  };

  const onCancelColumnEdit = () => {
    setEditColumnId(null);
  };

  const onCreateCell = (options: Pick<CreateColumnItem, 'fieldType' | 'columnIndex'>) => {
    const { fieldType, columnIndex } = options;

    setCreateColumnState({
      id: CREATE_COLUMNS_ID,
      name: listColumnTypeFields[fieldType].label,
      fieldType,
      columnIndex,
    });
  };

  const optionsListColumnStorage = {
    autoFetchAndRefetch: true,
    autoFetchParams: { id: listId },
    resetStoreOnChangeParams: { listId },
  };

  const optionsListRowsStorage = {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      id: listId,
      ...rowsParams,
      ...rowsParamsData,
    },
    resetStoreOnChangeParams: { listId },
  };

  const { data: listColumnsData, loading: isColumnsLoading } = useAbstractStorage(
    listColumnsStorage.storage,
    optionsListColumnStorage,
  );

  const { data: listRowsData, loading: isRowsLoading } = useAbstractStorage(
    listRowsStorage.storage,
    optionsListRowsStorage,
  );

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = { selectedRowKeys, onChange: onSelectChange, fixed: true };

  const dataSource: UiTableProps<DynamicTableRow>['dataSource'] = useStoreMap(
    listRowsStorage.storage.store,
    ({ data }) => getTableRows(data),
  );

  const isCreateColumnDisabled = listColumnsData.length === MAX_COLUMNS_COUNT;

  const getCreateColumnItems: GetCreateColumnItems = (params) => {
    const { columnIndex, showTooltip = true } = params || {};

    return Object.values(ListColumnFieldType).map((fieldType) => {
      const { label, icon, description } = listColumnTypeFields[fieldType];

      return {
        key: fieldType,
        icon: <UiIcon width={20} height={20} component={icon} />,
        onClick: () => onCreateCell({ fieldType, columnIndex }),
        label: (
          <UiTooltip
            open={showTooltip ? undefined : false}
            placement="left"
            title={description}
            shift={-25}
            getPopupContainer={(triggerNode) => triggerNode}
          >
            {label}
          </UiTooltip>
        ),
      };
    });
  };

  const getColumnIndex = (column: ListColumnModel) => {
    return listColumnsData.findIndex(({ id }) => id === column.id);
  };

  const onReorderColumn = (columnId: ListColumnId, toIndex: number) => {
    const columnsIds = listColumnsData.map(({ id }) => id);
    const reorderedColumnsIds = getReorderedIds(columnsIds, columnId, toIndex);

    return updateListColumnsOrderEffect({ id: listId, order: reorderedColumnsIds });
  };

  const onColumnDragEnd = (reorderedColumns: ColumnsType<ListRowValuesWithId>) => {
    const reorderedColumnsIds = getSortedColumnsIds(listColumnsData, reorderedColumns);

    setColumnsOptimisticOrder(reorderedColumnsIds);

    return updateListColumnsOrderEffect({ id: listId, order: reorderedColumnsIds })
      .catch((e) =>
        notification.error({ message: getErrorResponseMessage(e, 'Не удалось изменить порядок столбцов') }),
      )
      .finally(() => setColumnsOptimisticOrder(null));
  };

  const onHideColumn = (columnId: ListColumnId) => {
    return toggleVisibleListColumnEffect({ id: listId, columnId }).then(() => {
      notification.success({
        message: 'Столбец успешно скрыт',
        description: 'Столбец скрыт в списке и значения в нём стали необязательными',
      });
    });
  };

  const onColumnDelete = (columnId: ListColumnId) => {
    toggleIsBlocked(false);
    warning({
      title: 'Вы уверены, что хотите удалить этот столбец?',
      content: 'Все данные столбца будут потеряны без возможности восстановления',
      okText: 'Удалить',
      cancelText: 'Отмена',
      centered: true,
      onCancel: toggleIsBlocked,
      onOk: () => {
        deleteListColumnEffect({ id: listId, columnId }).then(() => toggleIsBlocked());
      },
    });
  };

  const onDuplicateColumn = (columnId: ListColumnId, columnIndex: number) => {
    copyListColumnEffect({ id: listId, columnId }).then(({ id }) => {
      if (columnIndex !== listColumnsData.length - 1) {
        onReorderColumn(id, columnIndex + 1);
      }
    });
  };

  const onUpdateRow = (rowId: ListRowId, rowValues: ListRowValues) => {
    return updateListRowEffect({
      id: listId,
      rowId,
      rowValues,
    })
      .then(() => {
        notification.success({
          message: 'Ячейка успешно изменена',
        });

        if (listColumnsData.some(({ fieldOptions }) => fieldOptions.groupValues)) {
          listRowsStorage.storage.refetchWithLastParams();
        }
      })
      .finally(() => {
        toggleIsBlocked(true);
      });
  };

  const getColumnDropdownActionItems: GetColumnDropdownActionItems = (column, index, options) => {
    const { id } = column;
    const { isTable } = options || {};
    const visibleColumnsData = listColumnsData.filter(({ isVisible }) => isVisible);
    const isFirstColumn = index === 0;
    const isLastColumn = index === visibleColumnsData.length - 1;

    const additionalItems = [
      {
        key: 'addToRight',
        label: 'Добавить столбец справа',
        icon: <UiIcon width={20} height={20} component={AddSvg} />,
        children: getCreateColumnItems({ columnIndex: index, showTooltip: false }),
        disabled: isCreateColumnDisabled,
      },
      {
        key: 'moveLeft',
        label: 'Переместить влево',
        icon: <UiIcon width={20} height={20} component={ArrowBackSvg} />,
        onClick: () => onReorderColumn(id, getColumnIndex(visibleColumnsData[index - 1])),
        disabled: isFirstColumn,
      },
      {
        key: 'moveRight',
        label: 'Переместить вправо',
        icon: <UiIcon width={20} height={20} component={ArrowFrontSvg} />,
        onClick: () => onReorderColumn(id, getColumnIndex(visibleColumnsData[index + 1])),
        disabled: isLastColumn,
      },
      {
        key: 'hide',
        label: 'Скрыть столбец',
        icon: <UiIcon width={20} height={20} component={HideSvg} />,
        onClick: () => onHideColumn(id),
      },
    ];

    return [
      {
        key: 'edit',
        label: 'Настройка столбца',
        icon: <UiIcon width={20} height={20} component={GearSvg} />,
        onClick: () => setEditColumnId(id),
      },
      ...(isTable ? additionalItems : []),
      {
        key: 'duplicateColumn',
        label: 'Дублировать столбец',
        icon: <UiIcon width={20} height={20} component={CopyFilledSvg} />,
        onClick: () => onDuplicateColumn(column.id, index),
      },
      ...(permissionMap.has(DynamicTablePermission.DeleteColumns)
        ? [
            {
              key: 'delete',
              label: 'Удалить',
              icon: <UiIcon width={20} height={20} component={DeleteSvg} />,
              onClick: () => onColumnDelete(id),
            },
          ]
        : []),
    ];
  };

  const columns = useMemo<ColumnsType<ListRowValuesWithId>>(() => {
    const columnsData: (ListColumnModel | CreateColumnItem)[] = listColumnsData.filter(
      ({ isVisible }) => isVisible,
    );

    if (columnsOptimisticOrder) {
      columnsData.sort((a, b) => columnsOptimisticOrder.indexOf(a.id) - columnsOptimisticOrder.indexOf(b.id));
    }

    if (createColumnState) {
      const { columnIndex } = createColumnState;

      if (columnIndex === undefined) {
        columnsData.push(createColumnState);
      } else {
        columnsData.splice(columnIndex + 1, 0, createColumnState);
      }
    }

    const formFieldsMap = {
      [ListColumnFieldType.Text]: <TextFormFields />,
      [ListColumnFieldType.Numeric]: <NumericFormFields />,
      [ListColumnFieldType.Boolean]: <BooleanFormFields />,
      [ListColumnFieldType.Datetime]: <DatetimeFormFields />,
      [ListColumnFieldType.Dictionary]: <DictionaryFormFields />,
      [ListColumnFieldType.Enum]: <EnumFormFields />,
      [ListColumnFieldType.Hyperlink]: <HyperlinkFormFields />,
      [ListColumnFieldType.User]: <UserFormFields />,
      [ListColumnFieldType.File]: <FileFormFields id={siteId} />,
    };

    return columnsData.map((column, index) => {
      const { id, fieldType } = column;
      const isEdit = editColumnId === id;
      const isCreate = id === CREATE_COLUMNS_ID;
      const isLastColumn = index === listColumnsData.length - 1;

      const onCreateSuccess = (columnId: ListColumnId) => {
        if (!isLastColumn) {
          onReorderColumn(columnId, index);
        }
      };

      const getColSpan = (record: ListRowValues, columnCell: ListColumnModel, length: number) => {
        if (!columnCell.fieldOptions.groupValues && record.children) {
          return 0;
        }

        if (record.children) {
          return length;
        }

        return 1;
      };

      return {
        title: (
          <TableHeaderCell
            column={column}
            open={isEdit || isCreate}
            containerRef={ref}
            extra={
              isColumnExist(column) && (
                <UiFlex gap={spaceXS}>
                  <DynamicTableSortButton listRowsStorage={listRowsStorage} column={column} />
                  <DynamicTableFilters listRowsStorage={listRowsStorage} containerRef={ref} column={column} />
                </UiFlex>
              )
            }
            popoverContent={
              <CreateUpdateColumn
                listId={listId}
                storage={listColumnsStorage}
                rowStorage={listRowsStorage.storage}
                fieldType={fieldType}
                onClose={isCreate ? onCancelColumnCreate : onCancelColumnEdit}
                columnId={id !== CREATE_COLUMNS_ID ? id : undefined}
                onCreateSuccess={onCreateSuccess}
                formFieldsMap={formFieldsMap}
              />
            }
            dropdownActions={
              isColumnExist(column) && permissionMap.has(DynamicTablePermission.EditColumns)
                ? getColumnDropdownActionItems(column, index, { isTable: true })
                : undefined
            }
          />
        ),
        dataIndex: id,
        key: id,
        width: 250,
        onCell: (record) => ({
          style: { padding: 1, verticalAlign: 'top' },
          onClick: () => toggleIsBlocked(false),
          ...(isColumnExist(column) && {
            colSpan: getColSpan(record, column, columnsData.length),
          }),
        }),
        render: (_, record) =>
          isColumnExist(column) ? (
            <DynamicTableCell
              readOnly={!permissionMap.has(DynamicTablePermission.EditCells)}
              onUpdate={onUpdateRow}
              column={column}
              containerRef={ref}
              record={record}
            />
          ) : null,
      };
    });
  }, [listColumnsData, createColumnState, editColumnId, permissionMap, columnsOptimisticOrder]);

  const cleanUpSelectionRow = () => {
    setSelectedRowKeys([]);
  };

  const onDelete = (rowIds: ListRowId | ListRowId[]) => {
    const isBatching = Array.isArray(rowIds);

    toggleIsBlocked(false);

    confirm({
      ...getDeleteModalProps(isBatching),
      onCancel: () => toggleIsBlocked(true),
      onOk: () => {
        const effect = isBatching
          ? deleteListRowsBatchEffect({ listId, rowIds })
          : deleteListRowEffect({ listId, listRowId: rowIds });

        effect
          .then(() =>
            notification.success({
              message: getSuccessMessageDeleteRow(isBatching),
            }),
          )
          .catch((e) =>
            notification.error({
              message: getErrorResponseMessage(e, getErrorMessageDeleteRow(isBatching)),
            }),
          )
          .finally(() => {
            toggleIsBlocked(true);

            if (isBatching) {
              cleanUpSelectionRow();
            }
          });
      },
    });
  };

  const deleteRows = (rowIds: ListRowId[]) => {
    onDelete(rowIds);
  };

  const onDeleteRow = (listRowId: ListRowId) => {
    onDelete(listRowId);
  };

  const onDeleteRows = () => {
    deleteRows(selectedRowKeys as ListRowId[]);
  };

  const addRow = (values?: ListRowValues, insertAfterId?: ListRowId) => {
    const params = values
      ? {
          id: listId,
          rowValues: normalizeValuesRow(values),
          insertAfterId,
        }
      : {
          id: listId,
          rowValues: Object.fromEntries(
            listColumnsData.map((column) => [
              column.id,
              column.fieldOptions.default || defaultValueCell[column.fieldType],
            ]),
          ),
        };

    createRowEffect(params).catch((e) =>
      notification.error({ message: getErrorResponseMessage(e, 'Не удалось добавить строку') }),
    );
  };

  const dropdownActions = useCallback(
    (row: ListRowValuesWithId): ActionsDropdownItem[] => {
      const { id, ...values } = row;
      const result = [
        {
          label: 'Информация о строке',
          icon: ViewSvg,
          onClick: () => setCurrentIdRowDetails(id),
        },
      ];

      if (permissionMap.has(DynamicTablePermission.EditRows)) {
        result.push({
          label: 'Дублировать строку вниз',
          icon: CopyFilledSvg,
          onClick: () => addRow(values, id),
        });
      }

      if (permissionMap.has(DynamicTablePermission.DeleteRows)) {
        result.push({ label: 'Удалить строку', icon: DeleteSvg, onClick: () => onDeleteRow(row.id) });
      }

      return result;
    },
    [permissionMap],
  );

  const renderActions = useCallback(
    (_: unknown, record: DynamicTableRow) => {
      const { id, children } = record;
      const items = dropdownActions(record);
      const isExpand = expandedRowKeys.includes(id);

      const onExpand = () => {
        setExpandedRowKeys((keys) => (isExpand ? keys.filter((key) => key !== id) : [...keys, id]));
      };

      return (
        <UiFlex justify="end" gap={space3XL}>
          {children && (
            <UiButton type="link" onClick={onExpand}>
              {`${isExpand ? 'Скрыть' : 'Показать'} строки`}
              <UiIcon component={isExpand ? DropUpSvg : DropDownSvg} width={20} height={20} />
            </UiButton>
          )}
          {!children && (
            <ActionsDropdown
              className={styles.dynamicTable__dropdownActionButton}
              items={items}
              recordId={id}
              getPopupContainer={() => ref.current || document.body}
            />
          )}
        </UiFlex>
      );
    },
    [dropdownActions, expandedRowKeys],
  );

  const selectAllRows = () => {
    setSelectedRowKeys(dataSource.map((item) => item.id));
  };

  const isLoading = isColumnsLoading || isRowsLoading;
  const rowData = listRowsData.find((row) => row.id === currentIdRowDetails);

  const selectionHeaderExtra = permissionMap.has(DynamicTablePermission.DeleteRows) ? (
    <UiButton
      icon={<UiIcon component={DeleteSvg} width={20} height={20} />}
      onClick={onDeleteRows}
      type="link-secondary"
      disabled={!selectedRowKeys.length}
    >
      Удалить
    </UiButton>
  ) : undefined;

  return (
    <UiFlex
      ref={ref}
      vertical
      gap={spaceL}
      className={classNames(styles.dynamicTable, {
        [styles.dynamicTable_fixHeight]: adminTable,
      })}
    >
      {renderHeader?.({
        listId,
        getColumnDropdownActionItems,
        getCreateColumnItems,
        listColumnsStorage,
        containerRef: ref,
      })}
      <UiTable<DynamicTableRow>
        className={styles.dynamicTable__table}
        dataSource={dataSource}
        pagination={false}
        loading={isLoading}
        isTitleEmptyPadding={!isLoading}
        rowSelection={isLoading || readOnly ? undefined : rowSelection}
        columns={columns}
        scroll={{ x: 'max-content' }}
        rowKey="id"
        expandable={{
          expandedRowKeys,
          expandIcon: () => undefined,
        }}
        title={
          readOnly
            ? undefined
            : () => (
                <UiRender type={UiRenderType.DisplayNone} visible={Boolean(selectedRowKeys.length)}>
                  <UiTable.SelectionHeader
                    extra={selectionHeaderExtra}
                    totalCount={dataSource?.length}
                    selectedCount={selectedRowKeys.length}
                  >
                    <UiButton type="link" onClick={selectAllRows}>
                      Выбрать все
                    </UiButton>
                    <UiButton type="link-secondary" onClick={cleanUpSelectionRow}>
                      Отмена
                    </UiButton>
                  </UiTable.SelectionHeader>
                </UiRender>
              )
        }
        footer={
          permissionMap.has(DynamicTablePermission.EditRows)
            ? () => (
                <UiButton
                  icon={<UiIcon width={20} height={20} component={AddSvg} />}
                  label="Добавить строку"
                  onClick={() => addRow()}
                  type="link"
                />
              )
            : undefined
        }
        draggableProps={
          permissionMap.has(DynamicTablePermission.EditColumns)
            ? {
                onColumnDragEnd,
                activatorColumnProps: {
                  title: (
                    <UiFlex justify="end">
                      <UiDropdown
                        getPopupContainer={() => ref.current || document.body}
                        trigger={['click']}
                        disabled={isCreateColumnDisabled}
                        menu={{
                          items: [
                            {
                              key: '1',
                              label: 'Тип столбца',
                              type: 'group',
                              children: getCreateColumnItems(),
                            },
                          ],
                        }}
                      >
                        <UiButton
                          type="link-secondary"
                          icon={<UiIcon width={20} height={20} component={AddSvg} />}
                        />
                      </UiDropdown>
                    </UiFlex>
                  ),
                  render: renderActions,
                  width: undefined,
                },
                renderCellOverlay: ({ id }) => {
                  const activeColumn = listColumnsData.find((item) => item.id === id);

                  return (
                    activeColumn && (
                      <TableHeaderCellTitle label={activeColumn.name} fieldType={activeColumn.fieldType} />
                    )
                  );
                },
              }
            : {
                activatorColumnProps: {
                  render: renderActions,
                },
              }
        }
      />
      {rowData && (
        <RowDetails
          rowData={rowData}
          columnsData={listColumnsData}
          open={Boolean(currentIdRowDetails)}
          onClose={() => setCurrentIdRowDetails('')}
        />
      )}
    </UiFlex>
  );
};

DynamicTable.Settings = DynamicTableSettings;
