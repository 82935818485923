import { Plugin } from '@ckeditor/ckeditor5-core';
import type {
  Element,
  Text,
  Writer,
  Document,
  AttributeElement,
  DowncastConversionApi,
  DowncastDispatcher,
  Position,
  Schema,
  DowncastAttributeEvent,
  Item,
} from '@ckeditor/ckeditor5-engine';

import type { MentionAttribute } from './Mention';
import { MentionCommand } from './MentionCommand';
import { addMentionAttributes } from './utils';

/**
 * Helper function to detect if mention attribute should be removed from selection.
 * This check makes only sense if the selection has mention attribute.
 *
 * The mention attribute should be removed from a selection when selection focus is placed:
 * a) after a text node
 * b) the position is at parents start - the selection will set attributes from node after.
 */
function shouldNotTypeWithMentionAt(position: Position): boolean {
  const { isAtStart } = position;
  const isAfterAMention = position.nodeBefore && position.nodeBefore.is('$text');

  return isAfterAMention || isAtStart;
}

/**
 * Checks if a node has a correct mention attribute if present.
 * Returns `true` if the node is text and has a mention attribute whose text does not match the expected mention text.
 */
function isBrokenMentionNode(node: Item | null): boolean {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  if (!node || !(node.is('$text') || node.is('$textProxy')) || !node.hasAttribute('mention')) {
    return false;
  }

  const text = node.data;
  const mention = node.getAttribute('mention') as MentionAttribute;

  // eslint-disable-next-line no-underscore-dangle
  const expectedText = mention._text;

  // eslint-disable-next-line eqeqeq
  return text != expectedText;
}

/**
 * Fixes a mention on a text node if it needs a fix.
 */
function checkAndFix(textNode: Item | null, writer: Writer): boolean {
  if (isBrokenMentionNode(textNode)) {
    // CHANGES:: Поменял удаление аттрибута на удаление всего Mention под нашу логику работы
    writer.remove(textNode!);

    return true;
  }

  return false;
}

/**
 * Creates a mention attribute value from the provided view element and optional data.
 *
 * This function is exposed as
 * {@link module:mention/mention~Mention#toMentionAttribute `editor.plugins.get( 'Mention' ).toMentionAttribute()`}.
 *
 * @internal
 */
// eslint-disable-next-line no-underscore-dangle
export function _toMentionAttribute(
  viewElementOrMention: Element,
  data?: MentionAttribute,
): MentionAttribute | undefined {
  const dataMention = viewElementOrMention.getAttribute('data-mention') as string;

  const textNode = viewElementOrMention.getChild(0) as Text;

  // Do not convert empty mentions.
  if (!textNode) {
    return;
  }

  const baseMentionData = {
    id: dataMention,
    _text: textNode.data,
  };

  // eslint-disable-next-line consistent-return
  return addMentionAttributes(baseMentionData, data);
}

/**
 * A converter that blocks partial mention from being converted.
 *
 * This converter is registered with 'highest' priority in order to consume mention attribute before it is converted by
 * any other converters. This converter only consumes partial mention - those whose `_text` attribute is not equal to text with mention
 * attribute. This may happen when copying part of mention text.
 */
function preventPartialMentionDowncast(dispatcher: DowncastDispatcher) {
  dispatcher.on<DowncastAttributeEvent>(
    'attribute:mention',
    (evt, data, conversionApi) => {
      const mention = data.attributeNewValue as MentionAttribute;

      if (!data.item.is('$textProxy') || !mention) {
        return;
      }

      const { start } = data.range;
      const textNode = start.textNode || (start.nodeAfter as Text);

      // eslint-disable-next-line no-underscore-dangle,eqeqeq
      if (textNode!.data != mention._text) {
        // Consume item to prevent partial mention conversion.
        conversionApi.consumable.consume(data.item, evt.name);
      }
    },
    { priority: 'highest' },
  );
}

/**
 * Creates a mention element from the mention data.
 */
function createViewMentionElement(
  mention: MentionAttribute,
  { writer }: DowncastConversionApi,
): AttributeElement | undefined {
  if (!mention) {
    return;
  }

  const attributes = {
    class: 'mention',
    'data-mention': mention.id,
  };

  const options = {
    id: mention.uid,
    priority: 20,
  };

  // eslint-disable-next-line consistent-return
  return writer.createAttributeElement('span', attributes, options);
}

/**
 * Model post-fixer that disallows typing with selection when the selection is placed after the text node with the mention attribute or
 * before a text node with mention attribute.
 */
function selectionMentionAttributePostFixer(writer: Writer, doc: Document): boolean {
  const { selection } = doc;
  const { focus } = selection;

  if (selection.isCollapsed && selection.hasAttribute('mention') && shouldNotTypeWithMentionAt(focus!)) {
    writer.removeSelectionAttribute('mention');

    return true;
  }

  return false;
}

/**
 * Model post-fixer that removes the mention attribute from the modified text node.
 */
function removePartialMentionPostFixer(writer: Writer, doc: Document, schema: Schema): boolean {
  const changes = doc.differ.getChanges();

  // CHANGES:: Поменял wasChanged оригинального плагина на wasRemoved под нашу логику работы
  let wasRemoved = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const change of changes) {
    // eslint-disable-next-line eqeqeq
    if (change.type == 'attribute') {
      // eslint-disable-next-line no-continue
      continue;
    }

    // Checks the text node on the current position.
    const { position } = change;

    // eslint-disable-next-line eqeqeq
    if (change.name == '$text') {
      const nodeAfterInsertedTextNode = position.textNode && position.textNode.nextSibling;

      // Checks the text node where the change occurred.
      wasRemoved = wasRemoved || checkAndFix(position.textNode, writer);

      // Occurs on paste inside a text node with mention.
      wasRemoved = wasRemoved || checkAndFix(nodeAfterInsertedTextNode, writer);
      wasRemoved = wasRemoved || checkAndFix(position.nodeBefore, writer);
      wasRemoved = wasRemoved || checkAndFix(position.nodeAfter, writer);
    }

    // Checks text nodes in inserted elements (might occur when splitting a paragraph or pasting content inside text with mention).
    // eslint-disable-next-line eqeqeq
    if (change.name != '$text' && change.type == 'insert' && !wasRemoved) {
      const insertedNode = position.nodeAfter as Element;

      // eslint-disable-next-line no-restricted-syntax
      for (const item of writer.createRangeIn(insertedNode!).getItems()) {
        wasRemoved = wasRemoved || checkAndFix(item, writer);
      }
    }

    // Inserted inline elements might break mention.
    // eslint-disable-next-line eqeqeq
    if (change.type == 'insert' && schema.isInline(change.name) && !wasRemoved) {
      const nodeAfterInserted = position.nodeAfter && position.nodeAfter.nextSibling;

      wasRemoved = wasRemoved || checkAndFix(position.nodeBefore, writer);
      wasRemoved = wasRemoved || checkAndFix(nodeAfterInserted, writer);
    }
  }

  return wasRemoved;
}

/**
 * This post-fixer will extend the attribute applied on the part of the mention so the whole text node of the mention will have
 * the added attribute.
 */
function extendAttributeOnMentionPostFixer(writer: Writer, doc: Document): boolean {
  const changes = doc.differ.getChanges();

  let wasChanged = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const change of changes) {
    // eslint-disable-next-line eqeqeq
    if (change.type === 'attribute' && change.attributeKey != 'mention') {
      // Checks the node on the left side of the range...
      const { nodeBefore } = change.range.start;
      // ... and on the right side of the range.
      const { nodeAfter } = change.range.end;

      // eslint-disable-next-line no-restricted-syntax
      for (const node of [nodeBefore, nodeAfter]) {
        if (
          isBrokenMentionNode(node) &&
          // eslint-disable-next-line eqeqeq
          node!.getAttribute(change.attributeKey) != change.attributeNewValue
        ) {
          writer.setAttribute(change.attributeKey, change.attributeNewValue, node!);

          wasChanged = true;
        }
      }
    }
  }

  return wasChanged;
}

/**
 * The mention editing feature.
 *
 * It introduces the {@link module:mention/mentioncommand~MentionCommand command} and the `mention`
 * attribute in the {@link module:engine/model/model~Model model} which renders in the {@link module:engine/view/view view}
 * as a `<span class="mention" data-mention="@mention">`.
 */
export class MentionEditing extends Plugin {
  /**
   * @inheritDoc
   */
  public static get pluginName() {
    return 'MentionEditing' as const;
  }

  /**
   * @inheritDoc
   */
  public init(): void {
    const { editor } = this;
    const { model } = editor;
    const doc = model.document;

    // Allow the mention attribute on all text nodes.
    model.schema.extend('$text', { allowAttributes: 'mention' });

    // Upcast conversion.
    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        key: 'data-mention',
        classes: 'mention',
      },
      model: {
        key: 'mention',
        value: (viewElement: Element) => _toMentionAttribute(viewElement),
      },
    });

    // Downcast conversion.
    editor.conversion.for('downcast').attributeToElement({
      model: 'mention',
      view: createViewMentionElement,
    });
    editor.conversion.for('downcast').add(preventPartialMentionDowncast);

    doc.registerPostFixer((writer) => removePartialMentionPostFixer(writer, doc, model.schema));
    doc.registerPostFixer((writer) => extendAttributeOnMentionPostFixer(writer, doc));
    doc.registerPostFixer((writer) => selectionMentionAttributePostFixer(writer, doc));

    editor.commands.add('mention', new MentionCommand(editor));
  }
}
