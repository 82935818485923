import {
  UiFile,
  UiFileDirection,
  UiFlex,
  UiTooltip,
  UiTreeKey,
  UiTruncateMarkup,
  UiTypography,
  UiTypographySymbolName,
  getFormattedFileSize,
  notification,
  useSpace,
  useToggle,
} from '@vkph/ui';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getFileStorageFolderInfoStorage } from '@vkph/common/store';
import { isFileStorageSitesContext } from '@vkph/common/types';
import { ListFileResponse } from '@vkph/common/types/models';
import { getFormattedDate, getRoutePath, RouteNames } from '@vkph/common/utils';

import { CellInput } from '../../../cell-input';
import { DynamicTableCellContentProps } from '../types';
import { FileCellForm } from './file-cell-form/FileCellForm';

type Props = DynamicTableCellContentProps<ListFileResponse | undefined>;

export const FileCell: FC<Props> = (props) => {
  const { value, inputSuffix, dataIndex, readOnly, fieldOptions, onFinish } = props;
  const { folderId } = fieldOptions;

  const navigate = useNavigate();
  const { spaceXL } = useSpace();
  const [isOpen, toggleIsOpen] = useToggle([false, true]);
  const [selectedKey, setSelectedKey] = useState<UiTreeKey | null>(value?.id || null);
  const [isShowUploadArea, toggleIsShowUploadArea] = useToggle([false, true]);

  const { storage: fileStorageFolderInfoStorage } = useMemo(getFileStorageFolderInfoStorage, [
    isShowUploadArea,
  ]);

  const { data: fileStorageFolderInfoData, fetchFx: fetchFileStorageFolderInfo } = useAbstractStorage(
    fileStorageFolderInfoStorage,
    {
      autoFetchAndRefetch: !readOnly && isOpen && Boolean(folderId),
      autoFetchParams: {
        id: folderId || '',
      },
      resetStoreOnUnmount: true,
      cancelPendingRequestOnUnmount: true,
    },
  );

  const closeModal = () => {
    toggleIsOpen();
  };

  const onSelect = useCallback((selectedKeys: UiTreeKey[]) => {
    if (selectedKeys.length) {
      setSelectedKey(selectedKeys[0]);
    }
  }, []);

  const hasValue = Boolean(selectedKey && value?.id);

  const onUpdateCell = () => {
    if (selectedKey) {
      onFinish({ [dataIndex]: String(selectedKey) });
      closeModal();
    }
  };

  const onUploadFile = () => {
    toggleIsShowUploadArea();
  };

  const navigateToFile = async () => {
    if (folderId && value?.id) {
      try {
        const { context, slug } = await fetchFileStorageFolderInfo({ id: folderId });

        if (isFileStorageSitesContext(context) && slug) {
          navigate(
            getRoutePath(RouteNames.SiteFile, {
              slug: context.siteSlug,
              serviceSlug: slug,
              fileId: value.id,
            }),
          );
        } else {
          notification.error({ message: 'Не удалось открыть информацию о файле' });
        }
      } catch (e) {
        notification.error({ message: 'Не удалось открыть файл' });
      }
    }
  };

  return (
    <>
      {fileStorageFolderInfoData && folderId && (
        <FileCellForm
          isOpen={isOpen}
          rootId={folderId}
          selectedKey={selectedKey}
          title={fileStorageFolderInfoData.name}
          onUploadFile={onUploadFile}
          closeModal={closeModal}
          onSelect={onSelect}
          onFinish={onUpdateCell}
        />
      )}
      <UiTooltip title={value?.name} placement="top" shift={spaceXL}>
        <UiFlex style={{ position: 'relative' }}>
          <CellInput.Button
            onClick={readOnly ? navigateToFile : () => toggleIsOpen()}
            suffix={inputSuffix}
            isSmallPadding={hasValue}
            isEmpty={!hasValue}
          >
            {value && (
              <UiFile
                direction={UiFileDirection.Horizontal}
                title={
                  <UiTypography.Paragraph style={{ textAlign: 'left', marginBottom: 0 }} strong>
                    <UiTruncateMarkup truncate>{value.name}</UiTruncateMarkup>
                  </UiTypography.Paragraph>
                }
                subtitle={
                  <UiTypography.Text type="secondary">
                    {value.fileData && (
                      <UiTruncateMarkup truncate>
                        {getFormattedFileSize(value.fileData.size)} •
                        {getFormattedDate(value.fileData.createdAt)}
                      </UiTruncateMarkup>
                    )}
                    {!value.fileData && <UiTypography.Symbol name={UiTypographySymbolName.EmDash} />}
                  </UiTypography.Text>
                }
                fileName={value.name}
              />
            )}
            {!value && 'Добавить файл'}
          </CellInput.Button>
        </UiFlex>
      </UiTooltip>
    </>
  );
};
