import { UiIcon, UiButton, UiSelect, UiSelectProps, UiOptionData, UiCell } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import ClearSvg from '@vkph/ui/svg/clear.svg';

import { UserAvatar } from '../../user-avatar';

export interface AutoCompleteListItemFieldProps extends UiSelectProps<UiOptionData> {
  onRemove: () => void;
  onSearchClear: () => void;
  showAvatar?: boolean;
}

export const AutoCompleteListItemField: FC<AutoCompleteListItemFieldProps> = (props) => {
  const {
    onSearchClear,
    onRemove,
    onChange,
    options = [],
    showAvatar,
    optionLabelProp,
    value,
    ...restProps
  } = props;

  const getLabel = (val: UiOptionData) => (
    <UiCell title={val.label} avatar={<UserAvatar size="extraSmall" src={val?.data?.smallAvatar} />} />
  );

  const currentOptions = useMemo<UiOptionData[]>(() => {
    return showAvatar ? options.map((item) => ({ ...item, label: getLabel(item) })) : options;
  }, [options, showAvatar]);

  const currentValue = useMemo(() => {
    return showAvatar && value?.data?.smallAvatar ? { ...value, label: getLabel(value) } : value;
  }, [showAvatar, value, value?.data?.smallAvatar]);

  const onSelect = (val: UiOptionData, option: UiOptionData) => {
    onChange?.(val, option);
    onSearchClear();
  };

  const removeHandler = () => {
    onRemove();
    onSearchClear();
  };

  return (
    <>
      <UiSelect
        {...restProps}
        showSearch
        labelInValue
        allowClear={false}
        filterOption={false}
        optionLabelProp="label"
        onSelect={onSelect}
        onChange={onChange}
        options={currentOptions}
        value={currentValue}
      />
      <UiButton
        size="middle"
        type="link-secondary"
        onClick={removeHandler}
        icon={<UiIcon component={ClearSvg} width={20} height={20} />}
      />
    </>
  );
};
