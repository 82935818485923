import { UiSpinner } from '@vkph/ui';
import React, { FC, PropsWithChildren, useMemo } from 'react';

import { useQueryParams } from '@vkph/common/hooks';
import { GetCalendarEventsStorage, GetCalendarSelectedStorage } from '@vkph/common/store/calendar';
import { CalendarViewType } from '@vkph/common/types/models';

import styles from './CalendarAppearance.scss';
import { CalendarAppearancePermissions, CalendarAppearancePermissionsContext } from './context';
import { AppearanceDay } from './day/AppearanceDay';
import { AppearanceMonth } from './month/AppearanceMonth';
import { AppearanceToolbar } from './toolbar/AppearanceToolbar';
import { AppearanceWeek } from './week/AppearanceWeek';

export interface CalendarAppearanceProps extends CalendarAppearancePermissions {
  eventsStorage: GetCalendarEventsStorage;
  selectedStorage: GetCalendarSelectedStorage;
  loading?: boolean;
}

interface CalendarAppearanceComposition extends FC<PropsWithChildren<CalendarAppearanceProps>> {
  Toolbar: typeof AppearanceToolbar;
}

export const CalendarAppearance: CalendarAppearanceComposition = (props) => {
  const { eventsStorage, selectedStorage, loading, children, readOnly } = props;
  const {
    queryParams: { tab: currentTab },
  } = useQueryParams<{ tab: CalendarViewType }>();

  const isActiveTab = (tab: CalendarViewType) => tab === currentTab;

  const calendarPermissions = useMemo(() => ({ readOnly }), [readOnly]);

  return (
    <CalendarAppearancePermissionsContext.Provider value={calendarPermissions}>
      <div className={styles.calendarAppearance}>
        {children}
        <UiSpinner spinning={loading}>
          {isActiveTab(CalendarViewType.Day) && (
            <AppearanceDay selectedStorage={selectedStorage} eventsStorage={eventsStorage} />
          )}
          {isActiveTab(CalendarViewType.Week) && (
            <AppearanceWeek selectedStorage={selectedStorage} eventsStorage={eventsStorage} />
          )}
          {isActiveTab(CalendarViewType.Month) && (
            <AppearanceMonth eventsStorage={eventsStorage} selectedStorage={selectedStorage} />
          )}
        </UiSpinner>
      </div>
    </CalendarAppearancePermissionsContext.Provider>
  );
};

CalendarAppearance.Toolbar = AppearanceToolbar;
