import { UiButton, UiIcon, UiPopover, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import { isToday } from 'date-fns';
import React, { FC, PropsWithChildren, ReactNode, useContext } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GetCalendarEventsStorage } from '@vkph/common/store/calendar';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { getNearestHourInterval } from '@vkph/common/utils';
import RectangleSvg from '@vkph/ui/svg/rectangle.svg';

import { CalendarAppearancePermissionsContext } from '../../context';
import { daysOfWeekRu } from '../heading/MonthHeading';
import styles from './MonthFill.scss';
import { MonthFillWrapper } from './wrapper/MonthFillWrapper';

interface Props {
  dayOfMonth: Date;
  isDayOff?: boolean;
  eventsStorage: GetCalendarEventsStorage;
  extra?: ReactNode[];
}

export const MonthFill: FC<PropsWithChildren<Props>> = (props) => {
  const { dayOfMonth, isDayOff, extra, children, eventsStorage } = props;

  const { storage } = eventsStorage;
  const { refetchWithLastParams } = useAbstractStorage(storage);
  const { siteId } = eventsStorage.storage.getLastRequestParams() || {};
  const { readOnly } = useContext(CalendarAppearancePermissionsContext);

  const dateOfMonth = dayOfMonth.getDate();
  const isDateToday = isToday(dayOfMonth);

  const onHourOfDayClickHandler = () => {
    const [sinceDate, tillDate] = getNearestHourInterval(dayOfMonth);

    openGlobalModal(GlobalModalNames.CalendarCreateUpdateMeeting, {
      initialEventFormValues: { sinceDate, tillDate },
      onSuccess: refetchWithLastParams,
      siteId,
    });
  };

  return (
    <MonthFillWrapper
      onClick={onHourOfDayClickHandler}
      className={classNames(styles.monthFill, {
        [styles.monthFill_dayOff]: isDayOff,
        [styles.monthFill_today]: isDateToday,
        [styles.monthFill_readOnly]: readOnly,
      })}
      tabIndex={-1}
      readOnly={readOnly}
    >
      {isDateToday && (
        <UiIcon component={RectangleSvg} height={8} width={24} className={styles.monthFill__rectangle} />
      )}
      <UiTypography.Text
        className={classNames(styles.monthFill__date, {
          [styles.monthFill__date_dayOff]: isDayOff,
          [styles.monthFill__date_today]: isDateToday,
        })}
      >
        {dateOfMonth}
      </UiTypography.Text>
      {children}
      {extra && extra.length > 0 && (
        <UiPopover
          overlayClassName={styles.popover}
          trigger="click"
          title={
            <div className={styles.popover__title}>
              <UiTypography.Text type="secondary">{daysOfWeekRu[dayOfMonth.getDay() - 1]}</UiTypography.Text>
              <UiTypography.Title style={{ marginTop: 0, marginBottom: 0 }} level={1}>
                {dateOfMonth}
              </UiTypography.Title>
            </div>
          }
          content={<div className={styles.popover__content}>{extra}</div>}
        >
          <UiButton
            className={styles.monthFill__more}
            onClick={(event) => {
              event.stopPropagation();
            }}
            type="link"
          >
            Еще {extra.length}
          </UiButton>
        </UiPopover>
      )}
    </MonthFillWrapper>
  );
};
